import {ElmFrameworkPorts} from "../../ElmFrameworkPorts";
import {captureException} from "../ErrorReporter/ErrorReporter";
import {once} from "../../Lib/util";
import {auth, scopedAppleAuthProvider, scopedGoogleAuthProvider} from "../PushMessages/firebase";

// const {userPoolName, region, clientId, socialProviderRedirectUrl} = process.env.config.cognito;

export {
  handleSocialAuth,
  AppleAuthSuccess,
  GoogleAuthSuccess,
  FacebookAuthSuccess,
}


function handleSocialAuth(isHybrid: boolean, appPorts: ElmFrameworkPorts): void {
  appPorts.loadSocialLibraries?.subscribe(once(() => {
    // checkExistingSocialSessions(isHybrid, appPorts);
    // handleUnauth(isHybrid, appPorts.unauthenticate);
  }));
  handleSocialAuthTaps(appPorts, createAuthHandlers(isHybrid, appPorts));
}

function handleSocialAuthTaps(appPorts: ElmFrameworkPorts, authHandlers: AuthHandlers): void {
  appPorts.googleOauthSdkLoaded.send(null);
  appPorts.appleOauthSdkLoaded.send(null);
  appPorts.googleLogin?.subscribe(authHandlers.google);
  appPorts.appleLogin?.subscribe(authHandlers.apple);
}


// const handleUnauth = (isHybrid: boolean, unauthPort: PortOut): void => {
//   unauthPort?.subscribe(() => {
//     if (isHybrid) {
//       //todo: implement
//     } else {
//       // google login does not hold state
//       // toWebFacebookSdk()
//       //   .then((fbSdk) => {
//       //     fbSdk?.logout();
//       //   });
//     }
//   });
// };


// const checkExistingSocialSessions = (isHybrid: boolean, appPorts: ElmFrameworkPorts): void =>
//   isHybrid ?
//     checkHybridSocialSessions(appPorts) :
//     checkWebSocialSessions(appPorts);

// function checkHybridSocialSessions(appPorts: ElmFrameworkPorts): void {
//   getHybridFbLoginStatus()
//     .then(res => appPorts.receiveFacebookAuth.send(res))
//     // .catch(() =>
//     //   tryHybridSilentLoginWithGoogle().then(res => appPorts.receiveGoogleAuth.send(res)));
// }
//
// function checkWebSocialSessions(appPorts: ElmFrameworkPorts): void {
//   toWebFacebookSdk()
//     .then((fbSdk) =>
//       fbSdk && getWebFbLoginStatus(fbSdk) .then(res => appPorts.receiveFacebookAuth.send(res))
//     )
// }


function createAuthHandlers(isHybrid: boolean, appPorts: ElmFrameworkPorts): AuthHandlers {
  return isHybrid
    ? createHybridHandlers(appPorts)
    : createWebHandlers(appPorts);
}


// async function toWebFacebookSdk(): Promise<fb.FacebookStatic | undefined> {
//   const fbId = process.env.config.facebookId;
//   if (!fbId) return;
//   return toFacebookSdk(fbId).catch(always(undefined));
// }

// async function toWebGoogleSdk(appPorts: ElmFrameworkPorts): Promise<google.accounts.oauth2.CodeClient | undefined> {
//   const gId = process.env.config.googleClientId;
//   if (!gId) return;
//   return toGoogleAuthSdk(process.env.config.googleClientId, toGoogleAuthCodeCallback(appPorts));
// }

function createWebHandlers(appPorts: ElmFrameworkPorts): AuthHandlers {
  return {
    google: () => webAuthWithGoogle(appPorts),
    apple: () => webAuthWithApple(appPorts),
  };
}

function webAuthWithGoogle(appPorts: ElmFrameworkPorts): void {
  appPorts.socialWindowOpened.send(null);
  auth.signInWithPopup(scopedGoogleAuthProvider())
    .then(result => {
      const credential = result.credential as unknown as { idToken: string }; // firebase types are wrong
      appPorts.receiveGoogleAuth.send({ idToken: credential.idToken });
    })
    .catch(error => {
      console.error(error);
      appPorts.socialWindowClosed.send(null);
      captureException(error);
    })
}

function webAuthWithApple(appPorts: ElmFrameworkPorts): void {
  appPorts.socialWindowOpened.send(null);
  auth.signInWithPopup(scopedAppleAuthProvider())
    .then(result => {
      const credential = result.credential as unknown as { idToken: string }; // firebase types are wrong
      appPorts.receiveAppleAuth.send({ idToken: credential.idToken });
    })
    .catch(error => {
      console.error(error);
      appPorts.socialWindowClosed.send(null);
      captureException(error);
    })
}

// function toGoogleAuthCodeCallback(appPorts: ElmFrameworkPorts): (response: CodeResponse) => void {
//   return function(response: CodeResponse): void {
//     if (response.error || response.error_description) {
//       console.log(response.error_description || response.error);
//       appPorts.socialWindowClosed.send(null);
//       captureException(response.error_description || response.error);
//       return;
//     }
//     appPorts.receiveGoogleAuth.send({ serverAuthCode: response.code });
//   }
// }

// function webAuthWithFb(fbSdk: fb.FacebookStatic, appPorts: ElmFrameworkPorts): void {
//   appPorts.socialWindowOpened.send(null);
//   fbLogin(fbSdk)
//     .then(res => appPorts.receiveFacebookAuth.send(res))
//     .catch(err => {
//       appPorts.socialWindowClosed.send(null);
//       captureMessage('fb auth failed: ' + JSON.stringify(err, null, 2));
//     });


//   function fbLogin(fbSdk: fb.FacebookStatic): Promise<StatusResponse> {
//     return new Promise((resolve, reject) => {
//       fbSdk.login((res: StatusResponse) => {
//         if (res.status == 'connected') {
//           resolve(res);
//         } else {
//           reject(res);
//         }
//       }, {
//         scope: ''
//       });
//     });
//   }
// }

// function getWebFbLoginStatus(fbSdk: fb.FacebookStatic): Promise<StatusResponse> {
//   return new Promise((resolve, reject) => {
//     fbSdk.getLoginStatus((res: StatusResponse) => {
//       if (res.status == 'connected') {
//         resolve(res);
//       } else {
//         reject(res);
//       }
//     })
//   });
// }

//////////////////////////////////////////////////////////////////////////////////////////


function createHybridHandlers(appPorts: ElmFrameworkPorts): AuthHandlers {
  return {
    google: () => nativeAuthGoogle(appPorts),
    apple: () => nativeAuthApple(appPorts),
  };
}

// function nativeAuthFacebook(appPorts: ElmFrameworkPorts): void {
//   appPorts.socialWindowOpened.send(null);
//   getHybridFbLoginStatus()
//     .catch(() => loginWithFb())
//     .then(res => appPorts.receiveFacebookAuth.send(res))
//     .catch(err => {
//       appPorts.socialWindowClosed.send(null);
//       captureException(err);
//     });


// function loginWithFb(): Promise<FacebookAuthSuccess> {
//   return new Promise((resolve, reject) => {
//     window.facebookConnectPlugin.login(
//       ['public_profile', 'email', 'user_gender'],
//       res => resolve(res),
//       err => reject(err)
//     );
//   });
// }

// function getHybridFbLoginStatus(): Promise<FacebookAuthSuccess> {
//   return new Promise((resolve, reject) => {
//     window.facebookConnectPlugin.getLoginStatus(
//       res => res.status == 'connected' && res.authResponse.accessToken ? resolve(res) : reject(res),
//       err => reject(err)
//     );
//   });
// }

function nativeAuthGoogle(appPorts: ElmFrameworkPorts): void {
  appPorts.socialWindowOpened.send(null);
  loginWithGoogle()
    .then(result => {
      appPorts.receiveGoogleAuth.send({ idToken: result.idToken });
    })
    .catch(error => {
      appPorts.socialWindowClosed.send(null);
      throw new Error(error);
      // captureException(new Error(error));
    });

  // tryHybridSilentLoginWithGoogle()
  //   .catch(() => loginWithGoogle())
  //   .then(res => appPorts.receiveGoogleAuth.send(res))
  //   .catch(err => {
  //     console.log('nativeAuthGoogle err:', err);
  //     appPorts.socialWindowClosed.send(null);
  //     captureException(err);
  //   });

  function loginWithGoogle(): Promise<GoogleAuthSuccess> {
    const gId = (<any> process).env.config.googleClientId;
    if (!gId) return Promise.reject("No google client id defined");
    return new Promise((res, rej) => {
      // @ts-ignore
      window.FirebasePlugin.authenticateUserWithGoogle(gId, res, rej);
    })
  }
}

function nativeAuthApple(appPorts: ElmFrameworkPorts): void {
  appPorts.socialWindowOpened.send(null);
  loginWithApple()
    .then(sendTokenToElm)
    .catch(handleError);


  function sendTokenToElm(response: any): void {
    appPorts.receiveAppleAuth.send({ idToken: response.identityToken }); // https://github.com/twogate/cordova-plugin-sign-in-with-apple#success-callback-data-example
  }
  function loginWithApple(): Promise<{}> {
    return new Promise((res, rej) => {
      (window.cordova.plugins as any).SignInWithApple.signin({ requestedScopes: [0, 1] }, res,rej);
    });
  }
  function handleError(error: any) {
    appPorts.socialWindowClosed.send(null);
    captureException(error);
  }
}

// function tryHybridSilentLoginWithGoogle(): Promise<GoogleAuthSuccess> {
//   return new Promise((resolve, reject) => {
//     (<any>window.plugins).googleplus.trySilentLogin(
//       {offline: true, webClientId: process.env.config.googleClientId},
//       (res: GoogleAuthSuccess) => res.serverAuthCode ? resolve(res) : reject('no server auth code'),
//       (err: string) => reject(err)
//     );
//   });
// }

interface AuthHandlers {
  // facebook: () => void
  google: () => void
  apple: () => void
}



interface GoogleAuthSuccess {
  // accessToken: string
  // displayName: string
  // email: string
  // familyName: string
  // givenName: string
  idToken: string
  // imageUrl: string
  // refreshToken: string
  // serverAuthCode: string
  // userId: string
}

interface AppleAuthSuccess {}

interface FacebookAuthSuccess {
  authResponse: {
    accessToken: string
    userID: string
  },
  status: 'connected' | string
}


// declare global {
//   interface Window {
//     facebookConnectPlugin: {
//       login: (
//         permissions: string[],
//         successCb: (res: FacebookAuthSuccess) => void,
//         errCb: (err: any) => void
//       ) => void
//       getLoginStatus: (
//         successCb: (res: FacebookAuthSuccess) => void,
//         errCb: (err: any) => void
//       ) => void
//     }
//   }
// }
