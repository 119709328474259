import {property} from 'lit-element';
import {ElmLitElement} from "../ElmLitElement";

export {
  defineImgInputElement,
  ResizedImage,
  ResizingConfig,
}

const defineImgInputElement = (): void =>
  customElements.define('bky-image-input', ImageInput);


class ImageInput extends ElmLitElement {
  @property({type: Object, attribute: 'resize-config'}) resizeConfig: ResizingConfig | undefined;


  connectedCallback(): void {
    this.querySelector<HTMLInputElement>('input')
      ?.addEventListener('change', e => {
        const file = (<HTMLInputElement>e.target).files?.[0];
        if (file) {
          return toTransformedImage(file, this.resizeConfig)
            .then(image => this.fire('newResizedImage', image));
        }
      });
  }
}


function toTransformedImage(img: Blob, config?: ResizingConfig): Promise<ResizedImage> {
  return config ?
    resizeImage(img, config).catch(() => toResizedImageWithMissingSize(img)) :
    toResizedImageWithMissingSize(img);
}

function toResizedImageWithMissingSize(img: Blob): Promise<ResizedImage> {
  return Promise.resolve({
    width: 0, //todo: implement measurements
    height: 0,
    size: 0,
    imgBlob: img,
    blobUrl: window.URL.createObjectURL(img),
  });
}


const sizingMethods: { [k in SizingMethodName]: SizingMethod } = {
  contain: getContainSizes,
  cover: getCoverSizes,
};


function resizeImage(imgToResizeWithFixedOrientation: Blob, cfg: ResizingConfig): Promise<ResizedImage> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const img = new Image();
    img.onload = onLoad;
    img.onerror = reject;
    img.src = window.URL.createObjectURL(imgToResizeWithFixedOrientation);

    function onLoad() {
      const sizingMethod = sizingMethods[cfg.method];
      const size = sizingMethod(cfg.width, cfg.height, img.width, img.height);
      canvas.width = size.width;
      canvas.height = size.height;
      ctx.drawImage(img, 0, 0, size.width, size.height);
      canvas.toBlob(imgBlob => {
        imgBlob
          ? resolve({
              width: Math.round(size.width),
              height: Math.round(size.height),
              size: imgBlob.size,
              imgBlob: imgBlob,
              blobUrl: window.URL.createObjectURL(imgBlob),
            })
          : reject();
      }, 'image/jpeg', cfg.quality)
    }
  });
}

function getContainSizes(maxWidth: number, maxHeight: number, srcWidth: number, srcHeight: number) {
  //http://stackoverflow.com/a/41944292/592641
  if (srcWidth <= maxWidth && srcHeight <= maxHeight) {
    return {width: srcWidth, height: srcHeight};
  } else if (srcWidth / maxWidth > srcHeight / maxHeight) {
    return {width: maxWidth, height: srcHeight * maxWidth / srcWidth};
  } else {
    return {width: srcWidth * maxHeight / srcHeight, height: maxHeight};
  }
}
// @ts-ignore
function getCoverSizes(maxWidth, maxHeight, srcWidth, srcHeight): { width: number, height: number } {
  throw new Error('not implemented'); //todo: implement
}


//------------------------------------------------------------------------------------

interface ResizedImage {
  width: number
  height: number
  size: number
  imgBlob: Blob
  blobUrl: string
}


interface ResizingConfig<method extends SizingMethodName = SizingMethodName> {
  width: number,
  height: number,
  quality: number,
  method: method,
}

type SizingMethodName = 'contain' | 'cover';
type SizingMethod = (maxWidth: number, maxHeight: number, srcWidth: number, srcHeight: number) => { width: number, height: number };
