import {ElmFrameworkPorts} from "../../ElmFrameworkPorts";

export {
  handleDomPorts,
}

function handleDomPorts(ports: ElmFrameworkPorts): void {
  ports.focus?.subscribe((id: string) => {
    // requestAnimationFrame(() => {
      document.getElementById(id)?.focus();
    // });
  });
  ports.select?.subscribe((id: string) => {
    const element: HTMLElement | null = document.getElementById(id);
    if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) { element.select(); }
  });
}