
export {
  subRatio,
  translateRatio,
}

function subRatio(ratio: number, lowLimit: number, hiLimit: number): number {
  if (ratio < lowLimit) {
    return 0;
  } else if (ratio > hiLimit) {
    return 1;
  } else {
    return (ratio - lowLimit) / (hiLimit - lowLimit);
  }
}

function translateRatio(
  ratio: Ratio,
  lowLimit: Ratio,
  hiLimit: Ratio,
  lowTranslated: number,
  hiTranslated: number,
  bezierControlPoints?: [Ratio, Ratio, Ratio, Ratio]
): number {
  if (ratio < lowLimit) {
    return lowTranslated;
  } else if (ratio > hiLimit) {
    return hiTranslated;
  } else if (bezierControlPoints) {
    const subRatio = (ratio - lowLimit) / (hiLimit - lowLimit);
    const [ax, ay, bx, by] = bezierControlPoints;
    return bezier(
      subRatio,
      {x: lowTranslated, y: lowTranslated},
      { x: Math.abs(hiTranslated - lowTranslated) * ax
      , y: Math.abs(hiTranslated - lowTranslated) * ay
      },
      { x: Math.abs(hiTranslated - lowTranslated) * bx
      , y: Math.abs(hiTranslated - lowTranslated) * by
      },
      {x: hiTranslated, y: hiTranslated},
    ).x;
  } else {
    const subRatio = (ratio - lowLimit) / (hiLimit - lowLimit);
    return (hiTranslated - lowTranslated) * subRatio + lowTranslated;
  }
}



function bezier(t:Ratio, p0:Point, p1:Point, p2:Point, p3:Point):Point{
  const cX = 3 * (p1.x - p0.x),
    bX = 3 * (p2.x - p1.x) - cX,
    aX = p3.x - p0.x - cX - bX;
  
  const cY = 3 * (p1.y - p0.y),
    bY = 3 * (p2.y - p1.y) - cY,
    aY = p3.y - p0.y - cY - bY;
  
  
  return {
    x: (aX * Math.pow(t, 3)) + (bX * Math.pow(t, 2)) + (cX * t) + p0.x, 
    y: (aY * Math.pow(t, 3)) + (bY * Math.pow(t, 2)) + (cY * t) + p0.y,
  };
}

interface Point {
  x: number
  y: number
}
type Ratio = number; //[0,1]