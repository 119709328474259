import {ElmLitElement} from "../ElmLitElement";
import {property} from "lit-element";

export {
  defineCustomSelect
};

const defineCustomSelect = () =>
  customElements.define('custom-select', CustomSelect);


class CustomSelect extends ElmLitElement {
  private disconnect : Function | undefined;

  @property({ type: Boolean, attribute: 'opened', reflect: true }) opened = false;
  @property({ type: String, attribute: 'classes-when-opened' }) classesWhenOpened: string;

  connectedCallback() {
    super.connectedCallback();
    this.disconnect = init(this);
  }

  attributeChangedCallback(name: string, old: string | null, value: string | null) {
    super.attributeChangedCallback(name, old, value);
    name === 'opened' && handleToggleChange(this, { name, old, value });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.disconnect && this.disconnect();
  }
}

function handleToggleChange(thisEl: CustomSelect, args: { name: string, old: string | null, value: string | null }) {
  if (isOpen()) {
    setTimeout(addClickAwayListener);
    thisEl.classList.add(thisEl.classesWhenOpened);
  } else {
    thisEl.classList.remove(thisEl.classesWhenOpened);
  }

  function isOpen(): boolean { return args.old === null && typeof args.value === 'string'; }
  // These events have to be on body so they can happen before customTap is evaluated
  function addClickAwayListener() { document.body.addEventListener('click', onClickAway); }
  function removeClickAwayListener() { document.body.removeEventListener('click', onClickAway); }
  function onClickAway(event: Event) {
    if (targetIsNotNestedSelect()) {
      thisEl.opened = false;
      removeClickAwayListener();
    }
    function targetIsNotNestedSelect(): boolean {
      return event.target instanceof Element && !thisEl.isEqualNode(event.target) && !thisEl.contains(event.target);
    }
  }
}

function init(thisEl: CustomSelect): Function {
  initListeners(thisEl);

  function toggleOpen(event: Event) {
    const selectEl = event.currentTarget as CustomSelect;
    if (isValidOpenOrCloseTarget()) { selectEl.opened = !selectEl.opened; }
    function isValidOpenOrCloseTarget(): boolean {
      return event.target instanceof Element
        && (
          event.target.matches('select-element, option-value')
          || event.target.matches('select-element *, option-value *')
        );
    }
  }

  function clearListeners(nestedSelect: CustomSelect) {
    return function() { nestedSelect.removeEventListener('click', toggleOpen); }
  }

  function initListeners(nestedSelect: CustomSelect) {
    nestedSelect.addEventListener('click', toggleOpen);
  }

  return clearListeners(thisEl);
}
