import { defineCustomizedSlideSelectHorizontalElement } from '../ElmFramework/Lib/Ui/SlideSelectHorizontal/SlideSelectHorizontal';
import { translateRatio } from '../ElmFramework/Lib/Ui/SlideSelectHorizontal/SlideSelectUtil';

const componentName = 'customized-horizontal-slide-select' as const;

export {
  defineSelectHorizontalElement,
}

function defineSelectHorizontalElement():void {
  defineCustomizedSlideSelectHorizontalElement({
    componentName,
    elMutator: mutateItem,
  })
}


function mutateItem(el: HTMLElement, ratio: number, where: 'left' | 'right'): void {
  if (where == 'left') {
    (el.firstChild as HTMLElement).style.transform =
      `translate3d(-${translateRatio(ratio, 0.75, 0.99, 37, 0, [.71,.01,.63,.99])}%, 0, 0)`;
  } else {
    (el.firstChild as HTMLElement).style.transform =
      `translate3d(${translateRatio(ratio, 0.75, 0.99, 37, 0, [.71,.01,.63,.99])}%, 0, 0)`;
  }
  (el.firstChild?.firstChild?.firstChild as HTMLElement).style.transform =
    `rotateX(${translateRatio(ratio, .3, 1, 90, 0, [.5,0,1,0])}deg) scaleX(${translateRatio(ratio, 0.8, 0.97, 0.6, 1)})`;
}
