import {ElmLitElement} from "../ElmLitElement";

export {
  definePreventClickPropagationElement,
}

const definePreventClickPropagationElement = (): void =>
  customElements.define('prevent-click-propagation', PreventClickPropagationElement);



class PreventClickPropagationElement extends ElmLitElement {

  connectedCallback(): void {
    this.addEventListener('customTap', (e: Event) => e.stopPropagation());
  }
}


