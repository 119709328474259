import {property} from 'lit-element';
import {ElmLitElement} from '../ElmLitElement';
import {toTransitionDuration} from '../util';

export {
  defineDramaticViewElement,
};

const defineDramaticViewElement = (): void =>
  customElements.define('dramatic-view', DramaticView);


const ENTERING = 'ENTERING';
const EXITING = 'EXITING';

type MovementState = typeof ENTERING | typeof EXITING;
//child state
const SHOWN = 'shown';


class DramaticView extends ElmLitElement {
  @property({type: String, attribute: 'child-state'}) childState: null | 'shown' = null;
  @property({type: String, attribute: 'entering-styles'}) enteringStyles: null | string = null;

  attributeChangedCallback(name: string, old: string | null, value: string | null): void {
    super.attributeChangedCallback(name, old, value);
    switch (name) {
      case 'child-state': {
        if (value === SHOWN) {
          addChildProcedure(this);
        }
        break;
      }
      case 'entering-styles': {
        if (this.childState === SHOWN && this.firstChild && value) {
          (<Element>this.firstChild).setAttribute('style', value);
        }
        break;
      }
    }
  }

  appendChild<T extends Node>(newChild: T): T {
    if (this.firstChild) {
      this.insertBefore(newChild, this.firstChild);
    } else {
      Element.prototype.appendChild.call(this, newChild);
    }
    return newChild;
  }

  replaceChild<T extends Node>(newChild: Node, oldChild: T): T {
    const oldChildElement = oldChild as unknown as HTMLElement;
    removeChildProcedure(oldChildElement);
    this.appendChild(newChild);
    addChildProcedure(this);
    return oldChild;
  }

  removeChild<T extends Node>(oldChild: T): T {
    const child = removeChildProcedure(firstNonExitingChild(this)) as unknown as T | undefined;
    return child || oldChild;
  }
}


function addChildProcedure(parent: DramaticView) {
  //two timeouts are necessary probably because
  //how elm vdom orchestrates dom manipulations
  setTimeout(() =>
    setTimeout(() => {
      const firstChild = parent.firstChild as Element | null;
      if (firstChild) {
        if (parent.enteringStyles) {
          firstChild.setAttribute('style', parent.enteringStyles);
        } else {
          setClasses(firstChild, ENTERING);
        }
      }
    }),
  );
}


function removeChildProcedure(child: HTMLElement | undefined) {
  if (child) {
    child.removeAttribute('style');
    child.style.pointerEvents = 'none';
    setClasses(child, EXITING);
    setTimeout(() => child.remove(), toTransitionDuration(child));
    return child;
  }
}

function firstNonExitingChild(el: DramaticView): HTMLElement | undefined {
  return Array.from(el.children).reverse().find(child => !child.classList.contains(EXITING)) as HTMLElement | undefined;
}

function setClasses(el: Element, state: MovementState) {
  switch (state) {
    case ENTERING:
      el.classList.add(ENTERING);
      el.classList.remove(EXITING);
      break;
    case EXITING:
      el.classList.remove(ENTERING);
      el.classList.add(EXITING);
      break;
  }
}

