const theLocalStorage = createLocalStorage();

export {
  getItem,
  setItem,
  removeItem,
  clear,
};


function getItem<T = unknown>(key: string): T {
  const item = theLocalStorage.getItem(key);
  return item ? JSON.parse(item) : null;
}

function setItem(key: string, item: any): void {
  theLocalStorage.setItem(key, JSON.stringify(item));
}

function removeItem(key: string): void {
  theLocalStorage.removeItem(key);
}

function clear(): void {
  theLocalStorage.clear();
}


function createLocalStorage(): Persistence {
  if ('localStorage' in window) {
    return window.localStorage;
  } else {
    return {
      getItem: (k: string) => null,
      setItem: (k: string, v: string) => undefined,
      removeItem: (k: string) => undefined,
      clear: () => undefined,
    };
  }
}

interface Persistence {
  getItem: (k: string) => string | null
  setItem: (k: string, v: string) => void
  removeItem: (k: string) => void
  clear: () => void
}

