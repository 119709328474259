import {ElmFrameworkPorts} from "../../ElmFrameworkPorts";
import {debounce} from "throttle-debounce";

export {
  handleBrowser,
  getBrowser,
  Browser,
}

function handleBrowser({windowResize}: ElmFrameworkPorts): void {
  window.addEventListener('resize', debounce(200, () => windowResize.send(getBrowser())));
}

function getBrowser(): Browser {
  return {
    unitSize: parseInt(getComputedStyle(document.documentElement).fontSize, 10) || 10,
    pixelRatio: window.devicePixelRatio,
    width: window.innerWidth,
    height: window.innerHeight,
  };
}


interface Browser {
  pixelRatio: number
  unitSize: number
  width: number
  height: number
}