import {ElmFrameworkPorts} from "../../ElmFrameworkPorts";

export {
  handleHardwareNavButtons,
  handleDom,
}

function handleHardwareNavButtons(isHybrid: boolean, appPorts: ElmFrameworkPorts): void {
  if (isHybrid) {
    document.addEventListener("backbutton", () => appPorts.hardwareNavBack.send(null), false);
    appPorts.exitApp?.subscribe(() => {
      (<any>window.plugins).appMinimize.minimize();
    });
  }
}

function handleDom(appPorts: ElmFrameworkPorts): void {
  appPorts.setInputVal?.subscribe(({id, val}) => {
    const node = <HTMLInputElement>document.getElementById(id);
    node.value = val;
  });
}
