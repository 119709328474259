import {ElmFrameworkPorts} from "../../ElmFrameworkPorts";

export {
  handlePwaInstallEvents,
  isPwaInstalled,
}

function handlePwaInstallEvents(appPorts: ElmFrameworkPorts): void {
  let theEvent: BeforeInstallPromptEvent;
  // @ts-ignore
  window.addEventListener('beforeinstallprompt', (e: BeforeInstallPromptEvent) => {
    e.preventDefault();
    appPorts.receiveBeforeInstallPromptEvent.send(null);
    theEvent = e;
  });
  appPorts.installPwa?.subscribe(() => {
    theEvent.prompt();
    theEvent.userChoice.then(choiceResult => {
      appPorts.installPwaResult.send(choiceResult.outcome === 'accepted');
    });
  });
}


function isPwaInstalled(): boolean {
  return !!(
    window.matchMedia &&
    window.matchMedia('(display-mode: standalone)').matches ||
    (<any>window.navigator).standalone
  );
}

// https://stackoverflow.com/a/51847335/592641
/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {

  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed',
    platform: string
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;

}

