import {ElmLitElement} from "../ElmLitElement";
import {MaybeBool} from "../util";

export {
  definePikaBooDramaticViewElement,
}

const definePikaBooDramaticViewElement = (): void =>
  customElements.define('pika-boo-dramatic-view', NewElement);



class NewElement extends ElmLitElement {
  connectedCallback(): void {
    const {pikaBooEl, clipper, scrollTarget, bgOverlay, theBodyWrap} = findChildren(this);
    let isTouching = false;
    let hasScrolled = false; //it's set to false after the initial scroll
    let lastKnownStateWasNearBottom: MaybeBool = 'UNKNOWN';
    setInitialScroll(scrollTarget);

    pikaBooEl.addEventListener('touchstart', () => isTouching = true);
    pikaBooEl.addEventListener('touchend', () => {
      isTouching = false;
      tryExit();
    });

    theBodyWrap?.addEventListener('customTap', () => {
      if (!hasScrolled) {
        scrollTarget?.scrollBy({top: 320, behavior: 'smooth'});
      }
    });

    //expand
    scrollTarget?.addEventListener('scroll', function expandListener() {
      requestAnimationFrame(function () {
        if (scrollTarget.scrollTop > window.innerHeight / 3) {
          scrollTarget.removeEventListener('scroll', expandListener);
          clipper && (clipper.style.height = '100vh');
          bgOverlay?.classList.add('shown');
          hasScrolled = true;
        }
      });
    });

    scrollTarget?.addEventListener('scroll', function () {
      tryExit();
      maybeEmitNearBottom();
    });

    function tryExit() {
      requestAnimationFrame(() =>
        scrollTarget && scrollTarget.scrollTop < window.innerHeight / 6 && !isTouching &&
        setTimeout(() => pikaBooEl.fire('nearTop')));
    }

    function maybeEmitNearBottom() {
      requestAnimationFrame(() => {
        if (isScrollNearBottom(scrollTarget)) {
          if (lastKnownStateWasNearBottom !== true) {
            pikaBooEl.fire('nearBottom');
          }
          lastKnownStateWasNearBottom = true;
        } else {
          lastKnownStateWasNearBottom = false;
        }
      });
    }
  }
}



// const scrollTarget = this.querySelector('.theScrollTarget'); //todo: check why it wouldn't work
const findChildren = (parent: NewElement) => ({
  pikaBooEl: parent,
  clipper: parent.lastChild as HTMLElement | null,
  scrollTarget: (parent.lastChild?.firstChild ?? null) as HTMLElement | null,
  bgOverlay: parent.firstChild as HTMLElement | null,
  theBodyWrap: parent.querySelector('.theBodyWrap'),
});

const setInitialScroll = (scrollTarget: HTMLElement | null) =>
  setTimeout(() =>
    setTimeout(() =>
      setTimeout(() =>
        setTimeout(() =>
          setTimeout(() =>
            scrollTarget && (scrollTarget.scrollTop = window.innerHeight / 100 * 30)
          )
        )
      )
    )
  );

function isScrollNearBottom(el: HTMLElement | null) {
  //scrollHeight = content height
  //offsetHeight = viewport height
  const coef = .5;
  return el && (el.scrollHeight - el.offsetHeight * coef <= el.scrollTop + el.offsetHeight);
}
