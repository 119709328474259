import {AppPorts, UserAuthSession} from "./elm-ports";
import {getItem} from "./ElmFramework/Lib/LocalStorage";
import * as LocalStorage from './ElmFramework/Lib/LocalStorage';

const AUTH = 'AUTH';
const COMMON_STATIC_FILE = 'COMMON_STATIC_FILE';


export {
  handlePersistence,
  getPersisted,
}

function handlePersistence({  newUserAuthenticated }: AppPorts): void {
  newUserAuthenticated.subscribe(
    (auth: UserAuthSession | null): void =>
      null === auth ? LocalStorage.removeItem(AUTH) : LocalStorage.setItem(AUTH, auth)
  );
  newUserAuthenticated.subscribe((auth: UserAuthSession): void => LocalStorage.setItem(AUTH, auth));
}


function getPersisted() {
  return {
    auth: getItem(AUTH),
    commonStaticFile: LocalStorage.getItem(COMMON_STATIC_FILE),
  };
}
