import { LitElement } from 'lit-element';

export {
  ElmLitElement,
  fireEvent,
};

class ElmLitElement extends LitElement {
  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }
  
  fire(eventName: string, payload?: any, extras: EventInit = {}): void {
    fireEvent(this, eventName, payload, extras);
  }
}

function fireEvent(el: HTMLElement, eventName: string, payload?: any, extras: EventInit = {}): void {
  el.dispatchEvent(new CustomEvent(eventName, { detail: payload, ...extras }));
}
