import {property} from 'lit-element';
import {ElmLitElement} from '../ElmLitElement';
import {MaybeBool, observeDom} from '../util';
import {InitReorderChild, initReorderChild} from "./EventFullReorderChild";

export {
  defineEventFullElement,
  EventFull,
};

const defineEventFullElement = (): void =>
  customElements.define('event-full', EventFull);


class EventFull extends ElmLitElement {
  @property({type: Number, attribute: 'coef'}) coef = 1;
  @property({type: Boolean, attribute: 'emit-self-size'}) emitSelfSize = false;
  @property({type: Boolean, attribute: 'emit-first-child-size'}) emitFirstChildSize = false;
  @property({type: Boolean, attribute: 'emit-scroll'}) emitScrollNearBottom = false;
  @property({type: Boolean, attribute: 'emit-scroll-near-top'}) emitScrollNearTop = false;
  @property({type: Boolean, attribute: 'emit-attach'}) emitAttach = false;
  @property({type: Boolean, attribute: 'emit-scroll-always'}) emitScrollAlways = false;
  @property({type: Boolean, attribute: 'emit-mouse-move'}) emitMouseMove = false;
  @property({type: Boolean, attribute: 'emit-reorder-child'}) emitReorderChild = false;
  @property({
    type: String,
    attribute: 'emit-reorder-child-scrolling-context-selector'
  }) emitReorderChildScrollingContextSelector?: string = undefined;
  @property({
    type: String,
    attribute: 'emit-reorder-child-child-whitelist-selector'
  }) childWhiteListSelector?: string = undefined;
  @property({type: String, attribute: 'emit-reorder-child-child-selector-override'}) childSelector?: string = undefined;
  @property({type: Number, attribute: 'emit-reorder-child-top-scroll-trigger'}) topScrollTrigger: number = 0.2;
  @property({type: Number, attribute: 'emit-reorder-child-bottom-scroll-trigger'}) bottomScrollTrigger: number = 0.8;
  @property({type: Number, attribute: 'emit-reorder-child-top-scroll-offset'}) topScrollOffset: number = 0.1;
  @property({type: Number, attribute: 'emit-reorder-child-bottom-scroll-offset'}) bottomScrollOffset: number = 0.2;
  @property({type: Number, attribute: 'emit-document-mouse-up'}) emitDocumentMouseUp = false;

  emitsSelfSizeInitialized = false;
  emitsFirstChildSizeInitialized = false;
  observerNearBottom?: MutationObserver;
  observerNearTop?: MutationObserver;
  emitScrollAlwaysInitialized = false;
  emitMouseMoveInitialized = false;
  emitReorderChildInitialized?: InitReorderChild;
  emitDocumentMouseUpInitialized = false;

  attributeChangedCallback(name: string, old: string | null, value: string | null) {
    super.attributeChangedCallback(name, old, value);
    if (value && handlers[name]) {
      handlers[name](this);
    }
  }

  connectedCallback(): void {
    (this.emitSelfSize && handlers['emit-self-size'](this));
    (this.emitFirstChildSize && handlers['emit-first-child-size'](this));
    (this.emitScrollNearBottom && handlers['emit-scroll'](this));
    (this.emitScrollNearTop && handlers['emit-scroll-near-top'](this));
    (this.emitAttach && handlers['emit-attach'](this));
    (this.emitScrollAlways && handlers['emit-scroll-always'](this));
    (this.emitMouseMove && handlers['emit-mouse-move'](this));
    (this.emitReorderChild && handlers['emit-reorder-child'](this));
    (this.emitDocumentMouseUp && handlers['emit-document-mouse-up'](this))
  }

  disconnectedCallback(): void {
    this.observerNearBottom?.disconnect();
    this.observerNearTop?.disconnect();
    this.emitReorderChildInitialized?.destroy();
  }
}

const handlers: { [k: string]: (el: EventFull) => void } = {
  'emit-self-size': el => el.emitsSelfSizeInitialized ??= initSizeEmitter(el, el),
  'emit-first-child-size': el =>
    (!el.emitsFirstChildSizeInitialized && el.firstChild instanceof HTMLElement)
      ? el.emitsFirstChildSizeInitialized = initSizeEmitter(el, el.firstChild)
      : el.emitsFirstChildSizeInitialized,
  'emit-scroll': el => el.observerNearBottom ??= handleScrollNearBottom(el),
  'emit-scroll-near-top': el => el.observerNearTop ??= handleScrollNearTop(el),
  'emit-attach': el => requestAnimationFrame(() => el.fire('attached', el, {bubbles: false})),
  'emit-scroll-always': el => el.emitScrollAlwaysInitialized ??= handleScrollAlways(el),
  'emit-mouse-move': el => el.emitMouseMoveInitialized ||= initMouseMoveEmitter(el),
  'emit-reorder-child': el => el.emitReorderChildInitialized ??= initReorderChild(el),
  'emit-document-mouse-up': el => el.emitDocumentMouseUpInitialized ||= initDocumentMouseUp(el),
};

function handleScrollAlways(el: EventFull): true {
  el.addEventListener('scroll', () => {
    el.fire('onScroll', {x: el.scrollLeft, y: el.scrollTop});
  });
  return true;
}

function handleScrollNearTop(el: EventFull): MutationObserver {
  let lastKnownStateWasNearTop: MaybeBool = 'UNKNOWN';
  const observerNearTop = createMutationObserverForScrollNearTopEmitter();
  el.addEventListener('scroll', handleStateForScrollNearTopEmitter);
  handleStateForScrollNearTopEmitter();
  return observerNearTop;

  function createMutationObserverForScrollNearTopEmitter(): MutationObserver {
    const observer = new MutationObserver(handleMutationForScrollNearTopEmitter);
    observer.observe(el, {childList: true, subtree: true, characterData: true});
    return observer;
  }

  function handleStateForScrollNearTopEmitter(): void {
    requestAnimationFrame(() =>
      requestAnimationFrame(() => {
        if (isScrollNearTop(el)) {
          if (lastKnownStateWasNearTop !== true) {
            el.fire('nearTop');
          }
          lastKnownStateWasNearTop = true;
        } else {
          lastKnownStateWasNearTop = false;
        }
      }));
  }

  function handleMutationForScrollNearTopEmitter() {
    lastKnownStateWasNearTop = false;
    handleStateForScrollNearTopEmitter();
  }
}

function handleScrollNearBottom(el: EventFull): MutationObserver {
  let lastKnownStateWasNearBottom: MaybeBool = 'UNKNOWN';
  const observerNearBottom = createMutationObserverForScrollNearBottomEmitter();
  el.addEventListener('scroll', handleStateForScrollNearBottomEmitter);
  handleStateForScrollNearBottomEmitter();
  return observerNearBottom;

  function createMutationObserverForScrollNearBottomEmitter(): MutationObserver {
    const observer = new MutationObserver(handleMutationForScrollNearBottomEmitter);
    observer.observe(el, {childList: true, subtree: true, characterData: true});
    return observer;
  }

  function handleStateForScrollNearBottomEmitter(): void {
    requestAnimationFrame(() => {
      if (isScrollNearBottom(el)) {
        if (lastKnownStateWasNearBottom !== true) {
          el.fire('nearBottom');
        }
        lastKnownStateWasNearBottom = true;
      } else {
        lastKnownStateWasNearBottom = false;
      }
    });
  }

  function handleMutationForScrollNearBottomEmitter() {
    lastKnownStateWasNearBottom = false;
    handleStateForScrollNearBottomEmitter();
  }
}


function initSizeEmitter(thisEl: ElmLitElement, target: HTMLElement): true {
  window.addEventListener('resize', () => requestAnimationFrame(() => fireSize(thisEl, target)));
  requestAnimationFrame(() => fireSize(thisEl, target));
  observeDom(
    target,
    _ => requestAnimationFrame(() => fireSize(thisEl, target)),
    {childList: true, subtree: true},
  );
  // @ts-ignore
  (ResizeObserver && new ResizeObserver(() => fireSize(thisEl, target)).observe(target));
  return true;
}

function initMouseMoveEmitter(thisEl: ElmLitElement): true {
  thisEl.addEventListener('mousemove', (e) => {
    if (e.offsetY >= 0 && e.offsetY <= thisEl.offsetHeight && e.offsetX >= 0 && e.offsetX <= thisEl.offsetWidth) {
      thisEl.fire('onMouseMoveEvent', {
        x: e.offsetX,
        y: e.offsetY,
        width: thisEl.offsetWidth,
        height: thisEl.offsetHeight,
      });
    }
  });
  return true;
}

function fireSize(thisEl: ElmLitElement, target: HTMLElement): void {
  thisEl.fire('onSize', getSize(target), {bubbles: false});
}

function getSize(el: HTMLElement) {
  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
    contentWidth: el.scrollWidth,
    contentHeight: el.scrollHeight,
  };
}

function isScrollNearBottom(el: EventFull): boolean {
  //scrollHeight = content height
  //offsetHeight = viewport height
  return el.scrollHeight - el.offsetHeight * el.coef <= el.scrollTop + el.offsetHeight;
}

function isScrollNearTop(el: EventFull): boolean {
  return el.offsetHeight * el.coef > el.scrollTop;
}

function initDocumentMouseUp(thisEl: EventFull): true {
  document.body.addEventListener('mouseup', () => { thisEl.fire('onDocumentMouseUp'); });
  return true;
}
