import {property, html, TemplateResult} from 'lit-element';
import {ElmLitElement} from "../ElmLitElement";
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js'
export {
  defineInnerHtmlElement,
}

const defineInnerHtmlElement = (): void =>
  customElements.define('inner-html', InnerHtml);



class InnerHtml extends ElmLitElement {
  @property({type: String, attribute: 'html-content'}) htmlContent: string;

  protected render(): TemplateResult | void {
    return html`${unsafeHTML(this.htmlContent) }`;
  }
}


