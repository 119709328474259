import {captureFirstChildEvent, getElementIndex, observeDom} from "../util";
import {EventFull} from "./EventFull";

export {
  initReorderChild,
  InitReorderChild,
}

interface InitReorderChild {
  destroy: () => void
}


function initReorderChild(thisEl: EventFull): InitReorderChild {
  interface DraggableElementData {
    index: number;
    movementDelta: number;
    offsetY: number;
  }

  /** CHILD ELEMENT RELATED STATE */
  let metadata: Map<HTMLElement, DraggableElementData> = new Map();
  let draggableElements: HTMLElement[] = [];
  let reorderedDraggableItems: HTMLElement[] = [];

  /** SCROLL RELATED STATE */
  let scrollingContextElement: HTMLElement | null;
  let throttleScroll: boolean = false;
  let isScrolling: boolean = false;
  let scrollingAmount: number;
  let topVisible: boolean;
  let bottomVisible: boolean;

  let startScrollY: number;
  let previousScrollY: number;
  let scrollY: number;

  /** DRAG RELATED STATE */
  let draggingEl: HTMLElement | undefined;
  let draggingStarted: boolean = false;

  /** MOUSE/TOUCH RELATED STATE */
  let startMouseY: number | undefined;
  let previousMouseY: number | undefined;
  let mouseY: number | undefined;

  /** TOUCH RELATED STATE */
  let touchHoldTimeout: number | undefined;

  const wheelOpt = doesSupportsPassive() ? {passive: false} : false;
  const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

  cancelDragIfChangesHappenToChildElements();

  // event listeners
  captureFirstChildEvent('mousedown', thisEl, handleMouseDown);
  document.body.addEventListener('mousemove', handleMouseMove);
  document.body.addEventListener('mouseup', handleMouseUp);

  captureFirstChildEvent('touchstart', thisEl, handleTouchStart);
  document.body.addEventListener('touchmove', handleTouchMove, wheelOpt);
  document.body.addEventListener('touchend', handleTouchEnd);
  captureFirstChildEvent("contextmenu", thisEl, preventDefault);
  document.body.addEventListener('click', preventClickWhileDragging, true);



  function preventClickWhileDragging(e: MouseEvent): void {
    if (draggingStarted) {
      e.stopPropagation();
      e.stopImmediatePropagation();
      draggingStarted = false;
    }
  }

  function handleScroll(): void {
    previousScrollY = scrollY;

    if (isScrolling) {
      if (!throttleScroll) {
        requestAnimationFrame(keepScrollingAndReorderingItems);
      } else {
        return;
      }
      throttleScroll = true;
    } else {
      return;
    }
  }

  function keepScrollingAndReorderingItems() {
    if (!shouldStopScrolling()) {
      scrollingContextElement?.scrollBy(0, scrollingAmount);
      scrollY += scrollingAmount;
      if (mouseY) {
        startMouseY && updateDraggingElementPosition(mouseY, startMouseY);
        handleReorder(mouseY, mouseY + scrollY);
      }
    } else {
      isScrolling = false;
    }
    throttleScroll = false;
  }

  function handleMouseDown(e: MouseEvent, draggableEl: HTMLElement): void {
    if (e.button === 0) {
      if (shouldCancelDrag(e)) return;
      initDraggableElementsMetadata();
      initDraggingStateAndListeners(draggableEl);
      startMouseY = mouseY = previousMouseY = e.clientY;
      document.addEventListener(wheelEvent, preventDefault, wheelOpt);
    } else {
      return;
    }
  }

  function handleMouseMove(e: MouseEvent): void {
    if (draggingEl) {
      mouseY = e.clientY;
      draggingEl.style.transition = "none";
      if (startMouseY) {
        updateDraggingElementPosition(mouseY, startMouseY);
        if (!draggingStarted && didMouseMoveEnoughToStartDragging(mouseY, startMouseY)) startDragging();
      }
      handleReorder(mouseY, mouseY + scrollY);
      handleScrollStart();
      previousMouseY = e.clientY;

      function didMouseMoveEnoughToStartDragging(y: number, startY: number): boolean {
        return Math.abs(y - startY) > 10;
      }
    } else {
      return;
    }
  }

  function handleMouseUp(): void {
    endDragging();
  }

  function enableDraggingElementTransitions(): void {
    draggingEl?.style.removeProperty("transition");
  }

  function handleTouchStart(e: TouchEvent, draggableEl: HTMLElement): void {
    if (shouldCancelDrag(e)) return;

    let touch = e.touches[0];
    startMouseY = mouseY = previousMouseY = touch.clientY;

    touchHoldTimeout = setTimeout(() => {
      initDraggableElementsMetadata();
      initDraggingStateAndListeners(draggableEl);
      startDragging();
    }, 500) as unknown as number;
  }

  function handleTouchMove(e: TouchEvent): void {
    let touch = e.touches[0];
    mouseY = touch.clientY;
    const mouseDelta = startMouseY ? mouseY - startMouseY : 0;

    if (touchHoldTimeout && Math.abs(mouseDelta) > 10) {
      clearTimeout(touchHoldTimeout);
      touchHoldTimeout = undefined;
    }

    if (draggingEl) {
      if (e.cancelable) e.preventDefault();
      e.stopPropagation();

      draggingEl.style.transition = "none";
      startMouseY && updateDraggingElementPosition(mouseY, startMouseY);
      handleReorder(mouseY, mouseY + scrollY);
      handleScrollStart();

      previousMouseY = touch.clientY;
    }
  }

  function handleTouchEnd(e: TouchEvent): void {
    if (touchHoldTimeout) {
      clearTimeout(touchHoldTimeout);
      touchHoldTimeout = undefined;
    }

    endDragging();
    if (draggingStarted) {
      e.stopPropagation();
      draggingStarted = false;
    }
  }

  function initDraggableElementsMetadata(): void {
    draggableElements = toDraggableElements();
    draggableElements.forEach(initDraggableElementMetadata_and_updateReorderedDraggableElementsState);

    function initDraggableElementMetadata_and_updateReorderedDraggableElementsState(element: HTMLElement, index: number): void {
      metadata.set(element, {
        index,
        movementDelta: 0,
        offsetY: getElementPagePosition(element).top + element.offsetHeight / 2
      });
      reorderedDraggableItems = [...reorderedDraggableItems, element];
    }
    function toDraggableElements(): HTMLElement[] {
      const thisElChildren = Array.from(thisEl.children) as HTMLElement[];
      if (!thisEl.childWhiteListSelector) return thisElChildren;
      return thisElChildren.filter((element: HTMLElement) => element.matches(thisEl.childWhiteListSelector as string))
    }
  }

  interface UpdateMetadataArguments {
    element: HTMLElement;
    elementData: DraggableElementData;
    draggingElementData: DraggableElementData;
    movementDelta: number;
  }

  function updateMetadata({element, elementData, draggingElementData, movementDelta}: UpdateMetadataArguments): void {
    const {index: draggingIndex} = draggingElementData;
    const {index: elementIndex} = elementData;
    metadata.set(element, {
      index: draggingIndex,
      movementDelta,
      offsetY: movementDelta === 0
        ? getElementPagePosition(element).top + element.offsetHeight / 2
        : elementData.offsetY + movementDelta,
    });
    draggingEl && metadata.set(draggingEl, {
      ...draggingElementData,
      index: elementIndex,
    });
    swapItemsInDraggableItemsArrayByIndex(draggingIndex, elementIndex);

    function swapItemsInDraggableItemsArrayByIndex(a: number, b: number) {
      const array = reorderedDraggableItems;
      [array[a], array[b]] = [array[b], array[a]];
    }
  }

  /** DRAG BEHAVIOUR IMPLEMENTATION DETAILS */
  function shouldCancelDrag(e: Event): boolean {
    const target = e.target as HTMLElement;
    if (!thisEl.childSelector) return false;
    return !target.matches(thisEl.childSelector) && !target.matches(`${thisEl.childSelector} *`);
  }

  function initDraggingStateAndListeners(draggableEl: HTMLElement): void {
    scrollingContextElement = thisEl.emitReorderChildScrollingContextSelector
      ? document.querySelector(thisEl.emitReorderChildScrollingContextSelector)
      : thisEl;
    scrollingContextElement?.addEventListener("scroll", handleScroll);
    startScrollY = scrollingContextElement?.scrollTop ?? 0;
    scrollY = startScrollY;
    draggingEl = draggableEl;
    draggableElements.forEach((element: HTMLElement): void => { element.style.removeProperty("transition"); });
    updateParentElementVisibilityState();
  }

  function handleReorder(clientY: number, pageY: number): void {
    const direction = (previousMouseY && clientY - previousMouseY) || scrollY - previousScrollY;
    if (direction === 0) return; // opt out if no movement on Y axis
    const {getSibling, getDOMSibling, isReorderTriggered} = toHelperFunctions();
    let
      draggingElementData: DraggableElementData | undefined,
      sibling: HTMLElement | undefined,
      siblingData: DraggableElementData | undefined;
    if (
      (draggingElementData = draggingEl && metadata.get(draggingEl))
      && (sibling = draggingElementData && getSibling(draggingElementData.index))
      && (siblingData = sibling && metadata.get(sibling))
      && isReorderTriggered(siblingData)
    ) {
      moveSibling_and_updateMetadata(sibling, siblingData, draggingElementData)
    }

    function moveSibling_and_updateMetadata(siblingEl: HTMLElement, siblingElData: DraggableElementData, draggingElData: DraggableElementData): void {
      const siblingIndex: number = draggableElements.indexOf(siblingEl);
      const domSibling: HTMLElement | undefined = getDOMSibling(siblingIndex);
      const movementDelta = toMovementDelta();
      moveElementY(siblingEl, movementDelta);
      updateMetadata({element: siblingEl, elementData: siblingElData, movementDelta, draggingElementData: draggingElData});

      function toMovementDelta(): number {
        return siblingElData.movementDelta === 0 ? calculateDistance() : 0;

        function calculateDistance() {
          return domSibling ? domSibling.offsetTop - siblingEl.offsetTop : 0;
        }
      }
    }

    function isMouseDraggingDown(): boolean {
      return direction > 0;
    }

    function toHelperFunctions() {
      if (isMouseDraggingDown()) {
        return {
          getSibling: (index: number): HTMLElement | undefined => reorderedDraggableItems[index + 1],
          getDOMSibling: (index: number): HTMLElement | undefined => draggableElements[index - 1],
          isReorderTriggered: (siblingData: DraggableElementData): boolean => pageY > siblingData.offsetY,
        }
      } else {
        return {
          getSibling: (index: number): HTMLElement | undefined => reorderedDraggableItems[index - 1],
          getDOMSibling: (index: number): HTMLElement | undefined => draggableElements[index + 1],
          isReorderTriggered: (siblingData: DraggableElementData): boolean => pageY < siblingData.offsetY,
        }
      }
    }
  }

  function startDragging() {
    draggingEl?.setAttribute("reorder-child", "dragging-element");
    draggingStarted = true;
    thisEl.fire("customDragStart");
  }

  function endDragging() {
    if (!draggingEl) return;
    const fromIndex = getElementIndex(draggingEl, draggableElements);
    const toIndex = metadata.get(draggingEl)?.index;
    const fromOriginalToTargetPositionDeltaForDraggableElement = toIndex && draggableElements[toIndex].offsetTop - draggingEl.offsetTop;
    enableDraggingElementTransitions();
    if (isDraggingElementReordered()) {
      draggingEl.addEventListener("transitionend", emitReorderEventAndResetDraggableElements);
    }
    fromOriginalToTargetPositionDeltaForDraggableElement && moveElementY(draggingEl, fromOriginalToTargetPositionDeltaForDraggableElement);
    handleDraggingEnd({draggingSucceeded: true});

    function emitReorderEventAndResetDraggableElements(e: TransitionEvent) {
      thisEl.fire("onReorderChild", {fromIndex, toIndex});
      (e.currentTarget as HTMLElement).removeEventListener("transitionend", emitReorderEventAndResetDraggableElements);
      draggableElements.forEach(resetDraggableElementStyles);
      draggableElements = [];

      function resetDraggableElementStyles(el: HTMLElement): void {
        el.style.removeProperty("--offsetY");
        el.style.setProperty("transition", "none");
      }
    }

    function isDraggingElementReordered(): boolean {
      return Number.isInteger(fromIndex) && Number.isInteger(toIndex) && fromIndex !== toIndex;
    }
  }

  function handleDraggingEnd(cfg: {draggingSucceeded: boolean}): void {
    draggingEl?.removeAttribute("reorder-child");
    document.removeEventListener(wheelEvent, preventDefault);
    scrollingContextElement?.removeEventListener("scroll", handleScroll);
    metadata.clear();
    // we need draggableElements in transitionend, so we'll reset them there and not here
    thisEl.fire(cfg.draggingSucceeded ? 'customDragEnd' : 'customDragCancel');
    reorderedDraggableItems = [];
    draggingEl = undefined;
    startMouseY = undefined;
    previousMouseY = undefined;
    mouseY = undefined;
  }

  function updateDraggingElementPosition(y: number, startY: number) {
    const mouseDelta = y - startY;
    const scrollDelta = scrollY - startScrollY;
    draggingEl?.style.setProperty("--offsetY", `${mouseDelta + scrollDelta}px`);
  }

  /** */

  /** SCROLL BEHAVIOUR IMPLEMENTATION DETAILS */
  function handleScrollStart(): void {
    const topTriggerAsPercentOfWindow = thisEl.topScrollTrigger;
    const bottomTriggerAsPercentOfWindow = thisEl.bottomScrollTrigger;
    if (pointerInBottomScrollTriggerArea_and_bottomNotVisible()) {
      startScrolling(5);
    } else if (pointerInTopScrollTriggerArea_and_topNotVisible()) {
      startScrolling(-5);
    } else {
      isScrolling = false;
    }

    function pointerInBottomScrollTriggerArea_and_bottomNotVisible() {
      return !bottomVisible && mouseY && mouseY > window.innerHeight * bottomTriggerAsPercentOfWindow;
    }

    function pointerInTopScrollTriggerArea_and_topNotVisible() {
      return !topVisible && mouseY && mouseY < window.innerHeight * topTriggerAsPercentOfWindow;
    }
  }

  function shouldStopScrolling(): boolean {
    updateParentElementVisibilityState();
    return _shouldStopScrolling();

    function _shouldStopScrolling(): boolean {
      return parentIsFullyVisible()
        || pointerNotInScrollTriggerArea()
        || pointerInTopScrollTriggerAreaWhileParentTopVisible()
        || pointerInBottomScrollTriggerAreaWhileParentBottomVisible();
    }

    function parentIsFullyVisible(): boolean {
      return topVisible && bottomVisible;
    }

    function pointerNotInScrollTriggerArea(): boolean {
      if (!mouseY) return false;
      return mouseY > window.innerHeight * thisEl.topScrollTrigger
        && mouseY < window.innerHeight * thisEl.bottomScrollTrigger;
    }

    function pointerInTopScrollTriggerAreaWhileParentTopVisible() {
      if (!mouseY) return false;
      return topVisible && mouseY <= window.innerHeight * thisEl.topScrollTrigger;
    }

    function pointerInBottomScrollTriggerAreaWhileParentBottomVisible() {
      if (!mouseY) return false;
      return bottomVisible && mouseY >= window.innerHeight * thisEl.bottomScrollTrigger;
    }
  }

  function startScrolling(amount: number): void {
    if (!isScrolling) {
      scrollingAmount = amount;
      isScrolling = true;
      scrollingContextElement?.scrollBy(0, scrollingAmount);
      scrollY += amount;
    } else {
      return;
    }
  }

  /** */

  /** UTILITIES */
  function moveElementY(element: HTMLElement, amount: number) {
    element.style.setProperty("--offsetY", `${amount}px`);
  }

  function updateParentElementVisibilityState() {
    [
      topVisible,
      bottomVisible
    ] = Object.values(
      getElementVisibility(
        thisEl,
        {
          topWindowOffsetPercent: thisEl.topScrollOffset,
          bottomWindowOffsetPercent: thisEl.bottomScrollOffset
        })
    );
  }

  interface GetElementVisibilityOptions {
    topWindowOffsetPercent: number;
    bottomWindowOffsetPercent: number;
  }
  function getElementVisibility(
    element: HTMLElement, options?: GetElementVisibilityOptions
  ): { topVisible: boolean, bottomVisible: boolean } {
    const { topWindowOffsetPercent = 0, bottomWindowOffsetPercent = 0 } = options || {};
    const elementOffsetTop = getElementPagePosition(element).top - scrollY;
    const elementClientTop = elementOffsetTop - window.innerHeight * topWindowOffsetPercent;
    const elementClientBottom = elementOffsetTop + element.offsetHeight + window.innerHeight * bottomWindowOffsetPercent;
    return {topVisible: isTopVisible(), bottomVisible: isBottomVisible()};

    function isTopVisible(): boolean { return elementClientTop > 0; }
    function isBottomVisible(): boolean { return elementClientBottom < window.innerHeight; }
  }

  function cancelDragIfChangesHappenToChildElements(): void {
    observeDom(thisEl, () => {
      if (!draggingEl) return;
      draggableElements.forEach(element => { element.removeAttribute("style"); });
      draggableElements = [];
      handleDraggingEnd({draggingSucceeded: false});
    }, {childList: true, subtree: true});
  }

  function getElementPagePosition(element: HTMLElement): { top: number, left: number } {
    const parentPosition = element.offsetParent
      ? getElementPagePosition(element.offsetParent as HTMLElement)
      : {top: 0, left: 0};
    return { top: element.offsetTop + parentPosition.top, left: element.offsetLeft + parentPosition.left };
  }

  function preventDefault(e: Event) { return e.preventDefault(); }
  /** */

  return {
    destroy: () => {
      document.body.removeEventListener('mousemove', handleMouseMove);
      document.body.removeEventListener('mouseup', handleMouseUp);
      document.body.removeEventListener('touchmove', handleTouchMove);
      document.body.removeEventListener('touchend', handleTouchEnd);
      document.body.removeEventListener('click', preventClickWhileDragging);
    },
  };
}

// modern Chrome requires { passive: false } when adding event
function doesSupportsPassive(): boolean {
  let result = false;
  try {
    // @ts-ignore
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
      get: function () {
        result = true;
      }
    }));
  } catch (e) {
    return false;
  }
  return result;
}