import { ElmLitElement } from "../../ElmLitElement";
import {property, PropertyValues} from "lit-element";
import { clamp } from "ramda";

export {
  defineSliderRange,
};

const defineSliderRange = () => customElements.define('slider-range', SliderRange);

interface SliderRangeProperties {
  step: number
  min: number
  max: number
  startValue: number
  endValue: number
  minRange: number
}

class SliderRange extends ElmLitElement {
  @property({ type: Number, attribute: "step" }) step: number;
  @property({ type: Number, attribute: "min" }) min: number;
  @property({ type: Number, attribute: "max" }) max: number;
  @property({ type: Number, attribute: "start-value" }) startValue: number;
  @property({ type: Number, attribute: "end-value" }) endValue: number;
  @property({ type: Number, attribute: "min-range" }) minRange: number;

  isInitialized: { destroy: () => void } | null = null;

  connectedCallback() {
    super.connectedCallback();
    this.isInitialized ??= initialize(this);
  }

  updated(changedProperties: PropertyValues<SliderRangeProperties>) {
    // change values to min/max if they're initialized erroneously
    const minMaxCorrectedValues = toMinMaxValues(this.min, this.max, this.startValue, this.endValue);
    // change values to respect the min range setting if possible
    const changePayload = toMinRangeValues(this.min, this.max, this.minRange, minMaxCorrectedValues);
    // emit event if there are changes
    setTimeout(() => payloadToEmitChangeEvent(changePayload, this));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.isInitialized?.destroy();
  }
}

function initialize(thisEl: SliderRange): { destroy: () => void } {
  thisEl.addEventListener("pointerdown", onPointerDown);

  return {
    destroy: () => {
      document.body.removeEventListener("pointermove", onPointerMovedStartThumb);
      document.body.removeEventListener("pointermove", onPointerMovedEndThumb);
      document.body.removeEventListener("pointerup", onPointerUp);
    },
  }

  function onPointerDown(this: HTMLElement, e: PointerEvent): void {
    document.body.addEventListener("pointerup", onPointerUp);
    if (!e.isPrimary) return; // ignore weird double touch scenarios, user probably wants to zoom or something
    if (e.button !== 0) return; // ignore non left clicks on mouse; this shouldn't affect touch

    const change = pointerEventToChange(e, this as SliderRange);
    changeToEmitChangeEvent(change, this as SliderRange);
    addListenerBasedOnThumbKind(change.kind);

    function addListenerBasedOnThumbKind(kind: SliderThumb) {
      switch(kind) {
        case "StartValue":
          document.body.addEventListener("pointermove", onPointerMovedStartThumb);
          break;
        case "EndValue":
          document.body.addEventListener("pointermove", onPointerMovedEndThumb);
          break;
      }
    }
  }

  function onPointerUp(): void {
    document.body.removeEventListener("pointermove", onPointerMovedStartThumb);
    document.body.removeEventListener("pointermove", onPointerMovedEndThumb);
  }

  function onPointerMovedStartThumb(e: PointerEvent) {
    if (e.buttons !== 1) { // if mouse users let go of left click (or hold more buttons)
      document.body.removeEventListener("pointermove", onPointerMovedStartThumb);
      return;
    }
    pointerEventToEmitChangeEvent(e, thisEl, "StartValue");
  }

  function onPointerMovedEndThumb(e: PointerEvent) {
    if (e.buttons !== 1) { // if mouse users let go of left click (or hold more buttons)
      document.body.removeEventListener("pointermove", onPointerMovedEndThumb);
      return;
    }
    pointerEventToEmitChangeEvent(e, thisEl, "EndValue");
  }
}

// helpers
function toMinMaxValues(
  min: number, max: number, startValue: number, endValue: number
): SliderRangeChangePayload {
  if (startValue < min || endValue < min || startValue > max || endValue > max) {
    return {
      startValue: clamp(min, max, startValue),
      endValue: clamp(min, max, endValue),
    };
  }
  return { startValue, endValue };
}

function toMinRangeValues(
  min: number, max: number, minRange: number, changePayload: SliderRangeChangePayload
): SliderRangeChangePayload {
  if (changePayload.endValue - changePayload.startValue < minRange) { // range isn't respected
    if (changePayload.startValue + minRange <= max) { // move end if possible
      return {...changePayload, endValue: changePayload.startValue + minRange};
    }
    if (changePayload.endValue - minRange >= min) { // move start if possible
      return {...changePayload, startValue: changePayload.endValue - minRange};
    }
  }
  return changePayload;
}

function payloadToEmitChangeEvent(payload: SliderRangeChangePayload, thisEl: SliderRange): void {
  if (thisEl.startValue === payload.startValue && thisEl.endValue === payload.endValue) return;
  thisEl.fire('onChange', payload);
}

function changeToEmitChangeEvent(change: SliderRangeChange, thisEl: SliderRange): void {
  const payload = sliderRangeChangeToPayload(change, thisEl);
  payloadToEmitChangeEvent(payload, thisEl);
}

function pointerEventToEmitChangeEvent(e: PointerEvent, thisEl: SliderRange, defaultTo?: SliderThumb): void {
  const change = pointerEventToChange(e, thisEl, defaultTo);
  changeToEmitChangeEvent(change, thisEl);
}

// details
function pointerEventToChange(e: PointerEvent, thisEl: SliderRange, defaultTo?: SliderThumb): SliderRangeChange {
  const minRange = clamp(0, thisEl.max - thisEl.min, thisEl.minRange);
  const nearestValue = pointerPositionToNearestValue({
    pointerPositionRatio: toPointerPositionRatio(),
    min: thisEl.min,
    max: thisEl.max,
    step: thisEl.step,
  });
  switch (defaultTo) {
    case "StartValue":
      let minToRangeLimited = clamp(thisEl.min, thisEl.endValue - minRange, nearestValue);
      return toStartValue(minToRangeLimited);
    case "EndValue":
      let rangeToMaxLimited = clamp(thisEl.startValue + minRange, thisEl.max, nearestValue);
      return toEndValue(rangeToMaxLimited);
  }
  return defaultToStart() || defaultToEnd() || pickCloserValue();

  // details
  function defaultToStart(): StartValue | null { return nearestValue < thisEl.startValue ? toStartValue(nearestValue) : null; }
  function defaultToEnd(): EndValue | null { return nearestValue > thisEl.endValue ? toEndValue(nearestValue) : null; }
  function pickCloserValue(): SliderRangeChange {
    const data = getDistanceData();

    if (shouldChangeStart(data)) {
      if (doesStartChangeRespectMinRange(minRange, data.distanceFromEnd)) {
        return toStartValue(nearestValue);
      } else if (isMinRangeLimitedStartChangeValid(thisEl.min, minRange, thisEl.endValue)) {
        return toStartValue(thisEl.endValue - minRange);
      }
    } else {
      if (doesEndChangeRespectMinRange(minRange, data.distanceFromStart)) {
        return toEndValue(nearestValue);
      } else if (isMinRangeLimitedEndChangeValid(thisEl.max, minRange, thisEl.startValue)) {
        return toEndValue(thisEl.startValue + minRange);
      }
    }

    return toStartValue(thisEl.startValue); // NoOp

    function isMinRangeLimitedEndChangeValid(max: number, minChange: number, startValue: number): boolean {
      return startValue + minChange <= max;
    }
    function isMinRangeLimitedStartChangeValid(min: number, minRange: number, endValue: number): boolean {
      return endValue - minRange >= min;
    }
    function shouldChangeStart(data: { distanceFromStart: number, distanceFromEnd: number }): boolean {
      return data.distanceFromStart < data.distanceFromEnd;
    }
    function doesStartChangeRespectMinRange(minRange: number, distanceFromEnd: number): boolean {
      return distanceFromEnd >= minRange;
    }
    function doesEndChangeRespectMinRange(minRange: number, distanceFromStart: number): boolean {
      return distanceFromStart >= minRange;
    }
    function getDistanceData(): { distanceFromStart: number, distanceFromEnd: number } {
      return {
        distanceFromStart: Math.abs(thisEl.startValue - nearestValue),
        distanceFromEnd: Math.abs(thisEl.endValue - nearestValue),
      };
    }
  }
  function toPointerPositionRatio(): number {
    const width = thisEl.offsetWidth;
    const x = clamp(0, width, e.clientX - toElementClientX(thisEl));
    return x / width;
  }
}

function pointerPositionToNearestValue(cfg: {
  pointerPositionRatio: number
  min: number
  max: number
  step: number
}): number {
  const { pointerPositionRatio, min, max, step } = cfg;
  const numElements = arithmeticProgressionNumElements(max, min, step);
  const targetElementNum = clamp(0, numElements - 1, Math.round((numElements - 1) * pointerPositionRatio)) + 1;
  return arithmeticProgressionNthElement(min, step, targetElementNum);

  // helpers
  function arithmeticProgressionNumElements(max: number, min: number, step: number) { return 1 + (max - min) / step; }
  function arithmeticProgressionNthElement(min: number, step: number, n: number) { return min + (n - 1) * step; }
}

// helpers
function toElementClientX(element: HTMLElement): number {
  const parentPosition = element.offsetParent ? toElementClientX(element.offsetParent as HTMLElement) : 0;
  return element.offsetLeft + parentPosition;
}

// types
interface SliderRangeChangePayload {
  startValue: number
  endValue: number
}
interface StartValue {
  kind: "StartValue"
  value: number
}
interface EndValue {
  kind: "EndValue"
  value: number
}
type SliderRangeChange = StartValue | EndValue;
type SliderThumb = SliderRangeChange["kind"];

// type helpers
function toStartValue(value: number): StartValue { return { kind: "StartValue", value }; }
function toEndValue(value: number): EndValue { return { kind: "EndValue", value }; }

function sliderRangeChangeToPayload(change: SliderRangeChange, thisEl: SliderRange): SliderRangeChangePayload {
  switch(change.kind) {
    case "StartValue":
      return { startValue: change.value, endValue: thisEl.endValue };
    case "EndValue":
      return { startValue: thisEl.startValue, endValue: change.value };
  }
}
