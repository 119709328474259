import * as MainModule from 'MainModule';
import {os} from 'platform';
import * as ErrorReporter from './Feature/ErrorReporter/ErrorReporter';
import {handleClipboard} from './Feature/Clipboard/Clipboard';
import {handleWebShare} from './Feature/Share/Share';
import {handleAnalytics} from './Feature/Analytics/Analytics';
import {handleGoogleMapPorts} from './Lib/Ui/GoogleMap/GoogleMapPorts';
import {handlePayments} from './Feature/Payments/Payments';
import {handlePwaInstallEvents, isPwaInstalled} from './Feature/Pwa/Pwa';
import {handleNotifications} from './Feature/Toaster/Notifier';
import * as Routing from './Feature/Navigation/Routing';
import * as Geolocation from './Feature/Geolocation/Geolocation';
import {handleGeolocation} from './Feature/Geolocation/Geolocation';
import {getBrowser, handleBrowser} from './Feature/Browser/Browser';
import {handlePushNotifications} from './Feature/PushMessages/PushMessages';
import {handleSocialAuth} from './Feature/SocialAuth/SocialAuth';
import {handleDom, handleHardwareNavButtons} from './Feature/Platform/Platform';
import {alwaysVoid, apply, debounce, getUserAgent, onLoad} from './Lib/util';
import {defineDebouncedInput} from './Lib/Ui/DebouncedInput';
import {defineDramaticViewElement} from './Lib/Ui/DramaticView';
import {defineEventFullElement} from './Lib/Ui/EventFull';
import {definePatientBackgroundImageElement} from './Lib/Ui/PatientBgImg';
import {definePreventClickPropagationElement} from './Lib/Ui/PreventClickPropagation';
import {definePikaBooDramaticViewElement} from './Lib/DramaticViews/PikaBooDramaticView';
import {defineImgInputElement} from './Lib/Ui/ImageInput';
import {defineInnerHtmlElement} from './Lib/Ui/InnerHtml';
import {defineCrossTransitionElement} from './Lib/Ui/CrossTransitionView';
import { initCustomTap } from "./customTap";
import {AllPorts, ElmFrameworkPorts, OutPortsKeys} from './ElmFrameworkPorts';
import {AppPorts} from '../elm-ports';
import {setTitleAndDesc, whenReady} from './helpers';
import * as LocalStorage from './Lib/LocalStorage';
import {defineDramaElement} from './Lib/Ui/Drama';
import {defineSlideSelectHorizontalElement} from './Lib/Ui/SlideSelectHorizontal/SlideSelectHorizontal';
import {defineStripeInputElement} from './Lib/Stripe/StripeInput';
import {handleStripePorts} from './Lib/Stripe/stripe-ports';
import {defineVideoInputElement} from "./Lib/Ui/VideoInput";
import {defineVideoThumbnailElement} from "./Lib/Ui/VideoThumbnail";
import {asyncPolyfills, hybridMediaBtn} from "./synchronised-async-services";
import {defineTextAreaSelfSize} from "./Lib/Ui/TextAreaSelfSize";
import {handlePlacesPorts} from "./Feature/Places/PlacesPorts";
import {handleContactsPorts} from "./Feature/Contacts/ContactsPorts";
import {defineCustomSelect} from "./Lib/Ui/CustomSelect";
import {handleDomPorts} from "./Lib/Dom/dom-ports";
import {handleAuth} from "../auth";
import {defineSliderRange} from "./Lib/Ui/SliderRange/SliderRange";
import {handleHybridPorts} from "./Feature/Hybrid/Hybrid";

export {
  initElmFramework,
  InitConfig,
}


interface InitConfig {
  elSelector: string
  initArgs: object
  portHandlers: ((ports: AllPorts) => void)[]
  portHandlersOnReady: ((ports: AllPorts) => void)[]
}

function initElmFramework(config: InitConfig): void {
  ErrorReporter.context(() => {
    closeThisWindowIfRouteIsJustClose();
    initCustomTap(document.documentElement, (<any> process).env.isHybrid);
    blurInputsOnOutsideTouch();
    forcePreventPinchToZoomForIos(Boolean(os && os.family && os.family === 'iOS'));
    defineWebComponents();
    asyncPolyfills.initAsyncPolyfills().then(alwaysVoid);
    const app: { ports: ElmFrameworkPorts & AppPorts } = failsafeElmInit(config);
    handleClipboard(app.ports);
    handleWebShare((<any> process).env.isHybrid, app.ports);
    handleErrors(config, app.ports);
    handleAnalytics(config, app.ports);
    handleGoogleMapPorts(app.ports);
    handlePayments(app.ports);
    handlePwaInstallEvents(app.ports);
    handleScroll(app.ports);
    handleInputSelection(app.ports);
    handleTitleDescriptionUpdates(app.ports);
    handleDom(app.ports);
    handleNotifications(app.ports);
    Routing.handleNavigation(app.ports);
    handlePhoneCalls(app.ports);
    config.portHandlers.forEach(apply(app.ports));
    handleGeolocation((<any> process).env.isHybrid, app.ports);
    handleStripePorts(app.ports);
    handlePlacesPorts(app.ports);
    handleContactsPorts(app.ports);
    handleHybridPorts(app.ports)
    handleAuth(true, app.ports);
    handleTracking(app.ports);
    whenReady.then(() => {
      handleBrowser(app.ports);
      app.ports.windowResize.send(getBrowser()); //because unitSize needs onload
      hideSplash((<any> process).env.isWeb);
      handlePushNotifications((<any> process).env.isHybrid, Boolean(os && os.family && os.family === 'iOS'), app.ports);
      handleSocialAuth((<any> process).env.isHybrid, app.ports);
      handleLifeCycle(app.ports);
      handleKeyboard(process.env);
      handleHardwareNavButtons((<any> process).env.isHybrid, app.ports);
      handleNetworkConnectivity(app.ports);
      config.portHandlersOnReady.forEach(apply(app.ports));
    });
    onLoad.then(() => {
      app.ports.appLoaded.send(null);
      initOffline(app.ports);
    });
    app.ports.enoughInitialCpuIdle?.subscribe(() => {
    });
    handleDomPorts(app.ports);
  });
}


/////////////////////////////////////////////////////////////////////////

function failsafeElmInit(config: InitConfig) {
  try {
    return MainModule.Elm.MainModule.init(elmProgramArgs(config.elSelector, config.initArgs));
  } catch (e) {
    LocalStorage.clear();
    return MainModule.Elm.MainModule.init(elmProgramArgs(config.elSelector, config.initArgs));
  }
}

function handleTracking(appPorts: ElmFrameworkPorts): void {
  appPorts.requestTrackingPermissions.subscribe(() => {
    whenReady.then(() => {
      (cordova as any).plugins.idfa.requestPermission();
    });
  });
}


function handlePhoneCalls(appPorts: ElmFrameworkPorts): void {
  appPorts.callPhone?.subscribe(phoneNumber => {
    window.location.href = `tel://${phoneNumber}`;
    return false;
  });
}


function defineWebComponents(): void {
  defineDebouncedInput();
  defineDramaticViewElement();
  defineEventFullElement();
  definePatientBackgroundImageElement();
  definePreventClickPropagationElement();
  definePikaBooDramaticViewElement();
  defineImgInputElement();
  defineVideoInputElement();
  defineVideoThumbnailElement();
  defineInnerHtmlElement();
  defineCrossTransitionElement();
  defineDramaElement();
  defineSlideSelectHorizontalElement();
  defineStripeInputElement();
  hybridMediaBtn.defineHybridMediaElement().then(alwaysVoid);
  defineTextAreaSelfSize();
  defineCustomSelect();
  defineSliderRange();
}

function handleTitleDescriptionUpdates(appPorts: ElmFrameworkPorts): void {
  appPorts.setTitleAndDescription?.subscribe(setTitleAndDesc);
}

function handleScroll(appPorts: ElmFrameworkPorts): void {
  const androidKeyboardRiseUpTransitionDuration = 600;
  appPorts.scrollIntoView?.subscribe(conf =>
    setTimeout(() =>
      document
        .querySelector(conf.elementSelector)
        ?.scrollIntoView(conf), androidKeyboardRiseUpTransitionDuration));
}

function handleInputSelection(appPorts: ElmFrameworkPorts): void {
  appPorts.selectTextContent?.subscribe(elId => {
    const el: HTMLTextAreaElement | HTMLInputElement = <any>document.getElementById(elId);
    if (el && el.setSelectionRange) {
      el.setSelectionRange(0, 9999);
    } else if (el && el.select) {
      el.select();
    }
  });
}

function handleNetworkConnectivity(appPorts: ElmFrameworkPorts): void {
  window.addEventListener('online', () => {
    appPorts.weAreOnline.send(null);
  });
}

function handleKeyboard(processEnv: any): void {
  const keyboard = (<any>window).Keyboard;
  if (processEnv.isHybrid && os && os.family && os.family === 'iOS') {
    keyboard.hideFormAccessoryBar(true);
  }
  window.addEventListener('keyboardDidShow', () => {
    (<any>document.activeElement)?.scrollIntoViewIfNeeded?.(); // https://github.com/cjpearson/cordova-plugin-keyboard/issues/62#issuecomment-337952646
  });
}

function handleLifeCycle(appPorts: ElmFrameworkPorts): void {
  const debouncedResume = debounce(() => appPorts.lifeCycleEvent.send('Resume'), 500);
  const debouncedPause = debounce(() => appPorts.lifeCycleEvent.send('Pause'), 500);
  window.addEventListener('focus', e => {
    if (e.target === window) {
      debouncedResume();
    }
  }, true);
  window.addEventListener('blur', e => {
    if (e.target === window) {
      debouncedPause();
    }
  }, true);
  document.addEventListener('visibilitychange', e => {
    if (document.hidden) {
      debouncedPause();
    } else {
      debouncedResume();
    }
  }, true);
  document.addEventListener('resume', () =>
    hybridMediaBtn.isHybridMediaButtonTakingPicture()
      .then(isTakingPicture =>
        !isTakingPicture && debouncedResume()), false);
}

function blurInputsOnOutsideTouch(): void {
  let touchStartPosition: undefined | [number, number];
  document.documentElement.addEventListener('touchstart', (e: TouchEvent) => {
    const theTouch = e.changedTouches[0];
    touchStartPosition = theTouch ? [theTouch.screenX, theTouch.screenY] : undefined;
  });
  document.documentElement.addEventListener('touchmove', (e: TouchEvent) => {
    const theTouchEnd: undefined | [number, number] = e.changedTouches[0] ? [e.changedTouches[0].screenX, e.changedTouches[0].screenY] : undefined;
    if (!theTouchEnd) {
      return
    }
    touchStartPosition = touchStartPosition && (arePointsTooDistant(touchStartPosition, theTouchEnd) ? undefined : touchStartPosition);
  });
  document.documentElement.addEventListener('touchend', (e: TouchEvent) => {
    const activeElement = <HTMLElement>document.activeElement;
    const activeTagName = document.activeElement?.tagName;
    const eventTag = <Element>e.target;
    const eventTagName = eventTag.tagName;
    if (!preventsBlur(eventTag) && touchStartPosition && eventTagName != 'INPUT' && eventTagName != 'TEXTAREA' && (activeTagName == 'INPUT' || activeTagName == 'TEXTAREA')) {
      setTimeout(() => activeElement.blur());
    }
  });

  function preventsBlur(el: Element | null): boolean {
    return el ?
      el.hasAttribute('prevent-blur-on-tap') ?
        true :
        preventsBlur(el.parentElement) :
      false;
  }

  function arePointsTooDistant([x1, y1]: [number, number], [x2, y2]: [number, number]): boolean {
    return areTooDistant(x1, x2) || areTooDistant(y1, y2);

    function areTooDistant(a: number, b: number): boolean {
      return Math.abs(a - b) > 20;
    }
  }
}

function elmProgramArgs(elSelector: string, appArgs: object) {
  const osFamily = os && os.family;
  const userAgent = getUserAgent();
  return {
    node: document.querySelector(elSelector),
    flags: {
      appArgs,
      elmFrameworkArgs: {
        env: Object.assign(process.env, {
          isIos: osFamily === 'iOS', //https://github.com/bestiejs/platform.js#readme
          isAndroid: osFamily === 'Android',
          isHybridIos: osFamily === 'iOS' && process.env.isHybrid,
          isHybridAndroid: osFamily === 'Android' && process.env.isHybrid,
          supportsWebShare: !!(<any>navigator).share,
          supportsWebPayments: !!window['PaymentRequest'],
          pwaInstallStatus: isPwaInstalled(),
          osVersion: (os && os.version) ? os.version : "",
          browserName: userAgent.name,
          browserVersion: userAgent.version,
          referrer: document.referrer
        }),
        configArgs: process.env.config,
        browser: getBrowser(),
        timezoneOffset: -(new Date().getTimezoneOffset()),
        currTime: (new Date).getTime(),
        navigationLocation: Routing.toLocation(),
        geolocationAccessAllowed: Geolocation.getPersistedGeoLocationAccessStatus(),
        seeds: Array.from(crypto.getRandomValues(new Uint32Array(4)))
      }
    }
  };
}
function initOffline(appPorts: ElmFrameworkPorts) {
  if (process.env.isActiveDevelopment) return;
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js')
      .then((reg: ServiceWorkerRegistration) => {
        reg.addEventListener('updatefound', (e) => {
          if ((e.target as ServiceWorkerRegistration).active) {
            appPorts.appUpdated.send(null);
          }
        });
        setInterval(() => { reg.update().then(alwaysVoid); }, 60 * 1000);
      });
  }
}


function hideSplash(isWeb: boolean) {
  //this seems to be necessary in hybrid
  requestAnimationFrame(() =>
    requestAnimationFrame(() =>
      requestAnimationFrame(() =>
        requestAnimationFrame(() =>
        requestAnimationFrame(() =>
        requestAnimationFrame(() =>
        requestAnimationFrame(() =>
        requestAnimationFrame(() =>
        requestAnimationFrame(() =>
        requestAnimationFrame(() =>
        requestAnimationFrame(() =>
        requestAnimationFrame(() =>
          requestAnimationFrame(() =>
            requestAnimationFrame(() =>
              requestAnimationFrame(() =>
                isWeb ? hideWebSplash() : navigator.splashscreen.hide()
              )))))))))))))));
}


function handleErrors<SetUsrCtxPort extends OutPortsKeys>(config: InitConfig, ports: AllPorts): void {
  ports.detailedError?.subscribe(({msg}) => ErrorReporter.captureMessage(msg)); // extra in detailedError needs to be stringified if we want it in the message
  ports.generalError?.subscribe(err => ErrorReporter.captureMessage(err));
}

function forcePreventPinchToZoomForIos(isIos: boolean): void { //http://stackoverflow.com/a/39737249/592641
  if (isIos) {
    document.addEventListener('gesturestart', (e: any) => {
      e.preventDefault();
      e.stopPropagation()
    });
  }
}

// function failsafeStart() {
//   try {
//     bootstrap()
//   } catch (ex) {
//     ErrorReporter.captureException(ex, {extra: {context: 'failsafe start'}});
//     clearPersistence();
//     bootstrap();
//     // location.reload(true);
//   }
// }

function closeThisWindowIfRouteIsJustClose(): void {
// regexr.com/3rg8g
  const regex = new RegExp(`\/\/[^\/]+\/${(<any> process).env.config.justCloseUrl}$`, `g`);
  if (document.URL.match(regex)) {
    window.close();
  }
}


function hideWebSplash(): void {
  const splash = document.getElementById('splash');
  if (splash) {
    splash.className = 'leaving';
    ['transitionend',
      'webkitTransitionEnd',
      'oTransitionEnd',
      'otransitionend'
    ].forEach(e => splash.addEventListener(e, (_: any) => splash.remove()));
  } else {
    console.error('splash element not found');
  }
}