import {property} from 'lit-element';
// import {TemplateResult} from 'lit-html';
import {debounce} from "throttle-debounce";
import {ElmLitElement} from "../ElmLitElement";

export {
  defineDebouncedInput,
}

const defineDebouncedInput = () =>
  customElements.define('debounced-input', DebouncedInput);


class DebouncedInput extends ElmLitElement {
  @property({type: Number, attribute: 'debounce-time'}) debounceTime = 0;
  private debouncedEmitInput: ReturnType<typeof debounce>;

  connectedCallback(): void {
    this.debouncedEmitInput = debounce(this.debounceTime, val => this.fire('debouncedInput', val));
    const inputEl = <HTMLInputElement>this.firstChild;
    inputEl.addEventListener('input', () => {
      this.debouncedEmitInput(inputEl.value);
    });
  }
}
