/// <reference path="../../types/index.d.ts" />
import './init-elm-debugger';
import {initElmFramework} from './ElmFramework/Main';
import {handleSecureStorageIfSupported} from './Services/secure-storage';
import {defineSelectHorizontalElement} from './Ui/SlideSelectHorizontal';
import {defineSelectVerticalElement} from './Ui/SlideSelectVertical';
import {getPersisted, handlePersistence} from "./Persistence";

defineSelectHorizontalElement();
defineSelectVerticalElement();
initElmFramework({
  elSelector: 'elm-app-placeholder',
  initArgs: {
    configArgs: process.env.config,
    persisted: getPersisted(),
    auth: null,
    persistedStaticData: null,
    preFetchedStaticData: null,
  },
  portHandlers: [
    handlePersistence,
  ],
  portHandlersOnReady: [
    handleSecureStorageIfSupported,
  ],
});



declare global {
  interface Window {
    GotaGuy: any
    preFetchedCommonFile?: string
    preFetchedCommonFilePromise: Promise<string>
  }
}
