export {
  whenReady,
  setTitleAndDesc,
  openPopUpInCenterOfWindow
};

const whenReady: Promise<void> =
  new Promise(resolve =>
    process.env.isWeb ?
      document.addEventListener('DOMContentLoaded', () => resolve(), false) :
      document.addEventListener('deviceready', () => resolve(), false));

function setTitleAndDesc(config: { title: string, description: string }): void {
  document.title = config.title;
  document.querySelector('meta[name="description"]')?.setAttribute('content', config.description);
}

function openPopUpInCenterOfWindow({ url, title, popUpWidth, popUpHeight }: { url: string, title: string, popUpWidth: number, popUpHeight: number }): Window | null {
  let top = 0;
  let left = 0;
  if(window.top) {
    top = window.top.outerHeight / 2 + window.top.screenY - ( popUpHeight / 2);
    left = window.top.outerWidth / 2 + window.top.screenX - ( popUpWidth / 2);
  }
  
  const newWindow = window.open(url, title,
    `
    scrollbars=yes,
    width=${popUpWidth},
    height=${popUpHeight},
    top=${top},
    left=${left}
    `
  );
  if (null !== newWindow) {
    newWindow.focus();
  }
  return newWindow;
}