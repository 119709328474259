// <reference types="google.maps" />
// <reference types="ContactsX" />

import {ElmFrameworkPorts, PortIn} from "../../ElmFrameworkPorts";
import { fromDiagnosticStatus } from "../Geolocation/Geolocation";
import ContactsX from 'cordova-plugin-contacts-x';

export {
  handleContactsPorts,
  ContactOutput
};

interface ContactOutput {
  emails: string[],
  phoneNumbers: string[]
}

interface ContactResult {
  emails?: { value: string }[],
  phoneNumbers?: { value: string }[]
}


function handleContactsPorts(appPorts: ElmFrameworkPorts): void {
  appPorts.requestContacts?.subscribe( () => {
    ContactsX.find(function(success: ContactResult[]) {
        onRequestContactsSuccess(appPorts.receiveContacts, success)
    }, function (error: any) {
      (<any>window.cordova.plugins).diagnostic.requestContactsAuthorization(
        (status: any) => {
          if (status == "GRANTED" || status == "GRANTED_WHEN_IN_USE") {
            ContactsX.find(function(success: ContactResult[]) {
              onRequestContactsSuccess(appPorts.receiveContacts, success)
            }, () => {})
          } else {
            appPorts.receiveContactsPermissionsStatus.send(fromDiagnosticStatus(status))
            appPorts.failedToReceiveContacts.send(null)
          }
        }, () => {},
      )}
    , {
      fields: {
        phoneNumbers: true,
        emails: true
      }
    });
  })

  appPorts.requestContactsPermissionsStatus?.subscribe( () => {
    (<any>window.cordova.plugins).diagnostic.getContactsAuthorizationStatus(
      (status:any) => appPorts.receiveContactsPermissionsStatus.send(fromDiagnosticStatus(status)),
      handleRequestContactsPermissionsStatusErr
    )
  })

  function onRequestContactsSuccess(receiveContacts:PortIn<ContactOutput[]>|undefined, contacts: ContactResult[]):void {
    const results = contacts.flatMap(contact => {
      const emails = contact.emails?.map(email => email.value)
      const phoneNumbers = contact.phoneNumbers?.map(phoneNumber => phoneNumber.value)

      if (!emails && !phoneNumbers) return { emails: [], phoneNumbers: []}
      else
        return {
          emails: emails||[],
          phoneNumbers: phoneNumbers||[]
        }}
    ).filter(result => result.emails.length || result.phoneNumbers.length)

    if (receiveContacts) receiveContacts.send(results);
  }

  function handleRequestContactsPermissionsStatusErr(error: string) {
    throw new Error('getContactsAuthorizationStatusFromCordova: ' + error);
  }
}

