import { property } from 'lit-element';
import { ElmLitElement } from '../ElmLitElement';
import { toTransitionDuration } from '../util';

export {
  defineCrossTransitionElement,
}

const defineCrossTransitionElement = (): void =>
  customElements.define('cross-transition', CrossTransitionView);


class CrossTransitionView extends ElmLitElement {
  @property({type: Array, attribute: 'views-order'}) viewsOrder: string[];
  @property({type: String, attribute: 'current-view'}) currView: string | void;
  private newViewIsAfterOld = true;

  attributeChangedCallback(name: string, old: string | null, value: string | null): void {
    super.attributeChangedCallback(name, old, value);
    if (name === 'current-view') {
      this.newViewIsAfterOld = value && old ? this.viewsOrder.indexOf(value) > this.viewsOrder.indexOf(old) : true;
    }
  }

  removeChild<T extends Node>(oldChild: T): T {
    setTimeout(() => {
      if (this.childNodes.length == 1) {
        setExitingState(oldChild as unknown as HTMLElement, 'next');
      }
    });
    return oldChild;
  }

  appendChild<T extends Node>(fragment: T): T {
    const newChild = (fragment.nodeName === '#document-fragment' ? fragment.firstChild : fragment) as HTMLElement;
    setEnteringStartState(newChild, this.newViewIsAfterOld);
    insertAsFirstChild(newChild, this); //because that's what Elm expects
    initiateNewViewProcedure({
      newChild: newChild,
      oldChild: filterNotExiting(this.childNodes[1] as HTMLElement),
      newChildIsAfterOld: this.newViewIsAfterOld,
    });
    return fragment;
  }
}

//________________________________________________________________________________________

function insertAsFirstChild(newChild: HTMLElement, parent: HTMLElement): void {
  if (parent.firstChild) {
    parent.insertBefore(newChild, parent.firstChild);
  } else {
    Element.prototype.appendChild.call(parent, newChild);
  }
}


function setEnteringStartState(view: HTMLElement, newViewIsAfterOld: boolean): void {
  if (newViewIsAfterOld) {
    setEnteringState(view, 'next-start');
  } else {
    setEnteringState(view, 'previous-start');
  }
}


function filterNotExiting(child?: HTMLElement): HTMLElement | void {
  if (child && !child.hasAttribute('exiting' as State)) {
    return child;
  }
}


interface AddChildProcedureConfig {
  newChild: HTMLElement
  oldChild: HTMLElement | void
  newChildIsAfterOld: boolean
}

function initiateNewViewProcedure(config: AddChildProcedureConfig): void {
  if (config.oldChild) {
    if (config.newChildIsAfterOld) {
      setExitingState(config.oldChild, 'previous');
    } else {
      setExitingState(config.oldChild, 'next');
    }
  }
  //two timeouts are necessary probably because
  //how elm vdom orchestrates dom manipulations
  setTimeout(() =>
    setTimeout(() => {
      if (config.newChildIsAfterOld) {
        setEnteringState(config.newChild, 'end');
      } else {
        setEnteringState(config.newChild, 'end');
      }
    })
  );
}

function setEnteringState(view: HTMLElement, direction: DirectionEntering): void {
  setViewState(view, 'entering', direction);
}

function setExitingState(view: HTMLElement, direction: DirectionExiting): void {
  setViewState(view, 'exiting', direction);
}

function setViewState(view: HTMLElement, state: State, direction: DirectionEntering | DirectionExiting): void {
  view.setAttribute(state, direction);
  view.removeAttribute(oppositeState(state));
  if (state === 'exiting') {
    setTimeout(() => view.remove(), toTransitionDuration(view));
  }
}

type DirectionEntering = 'next-start' | 'previous-start' | 'end'
type DirectionExiting = 'next' | 'previous'
type State = 'entering' | 'exiting'

function oppositeState(state: State): State {
  return state === 'entering' ? 'exiting' : 'entering';
}
