// <reference types="google.maps" />
// <reference types="ContactsX" />

import {ElmFrameworkPorts} from "../../ElmFrameworkPorts";

export {handleHybridPorts};

function handleHybridPorts(appPorts: ElmFrameworkPorts): void {
  appPorts.goToAppSettings?.subscribe( () => {
    (<any>window.cordova.plugins).diagnostic.switchToSettings(
      () => {}, console.log
    )}
  )
}

