import * as Sentry from '@sentry/browser';
import {Integrations} from '@sentry/tracing';
import {whenReady} from "../../helpers";
import {Breadcrumb, CaptureContext} from "@sentry/types";
import {Scope} from "@sentry/core/types/scope";

export {
  captureBreadcrumb,
  captureMessage,
  captureException,
  context,
}

const sentry =
  process.env.isHybrid
    ? initSentryCordova((<any> process).env.isActiveDevelopment)
    : initWeb((<any> process).env.isActiveDevelopment);

const captureBreadcrumb = (b: Breadcrumb) => sentry.then(s => s.addBreadcrumb(b));
const captureMessage = (m: string, ctx?: CaptureContext) => sentry.then(s => s.captureMessage(m, ctx));
const captureException = (x: any, ctx?: CaptureContext) => sentry.then(s => s.captureException(x, ctx));

const context =
  process.env.isHybrid ?
    (fn: Function) => fn() : //todo: figure out context for hybrid
    (cb: (scope: Scope) => void) => sentry.then(s => s.configureScope(cb));


function initWeb(isDevelopment: boolean): Promise<typeof Sentry> {
  Sentry.init({
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  
    dsn: (<any> process).env.config.sentryId,
    ignoreErrors: isDevelopment ? [/.*/g] : [],
    environment: process.env.audience + '-' + process.env.platform,
    maxBreadcrumbs: 20,
    release: process.env.commit,
    // serverName: os,
    // maxMessageLength: 1000,
  });
  return Promise.resolve(Sentry);
}


function initSentryCordova(isDevelopment: boolean): Promise<typeof Sentry> {
  return whenReady.then(() => {
    const Sentry = cordova.require('sentry-cordova.Sentry');
    Sentry.init({
      ignoreErrors: isDevelopment ? [/.*/g] : [],
      dsn: (<any> process).env.config.sentryId,
      environment: process.env.audience + '-' + process.env.platform,
      release: process.env.commit,
      maxBreadcrumbs: 20,
      // serverName: os,
      stacktrace: true,
      // maxMessageLength: 1000,
    });
    return Sentry;
  });
}
