// @ts-nocheck
import {ElmFrameworkPorts} from "../../ElmFrameworkPorts";
import {captureException} from "../ErrorReporter/ErrorReporter";

export {
  handleWebShare
}

declare global {
  interface Window {
    plugins: any; 
  }
}

function handleWebShare(isHybrid: boolean, appPorts: ElmFrameworkPorts): void {
  appPorts.shareThroughWebApi?.subscribe(cmd => {
    (<any>navigator).share(cmd)
      .catch(captureException);
  });
  appPorts.shareThroughHybridApi?.subscribe(({title, body, url}) => {
    window.plugins.socialsharing.shareWithOptions({
      message: title,
      subject: body,
      // files: [],
      url: url,
    });
  });


  appPorts.shareViaFacebook?.subscribe(({title, body, url}) => {
    if (isHybrid) {
      window.plugins.socialsharing.shareViaFacebook(title, [], url);
    } else {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, 'facebook-popup', 'height=350,width=600');
    }
  });
  appPorts.shareViaTwitter?.subscribe(({title, body, url}) => {
    if (isHybrid) {
      window.plugins.socialsharing.shareViaTwitter(title, undefined, url);
    } else {
      window.open(`https://twitter.com/share?url=${url}`, 'twitter-popup', 'height=350,width=600');
    }
  });
  appPorts.shareViaLinkedIn?.subscribe(({title, body, url}) => {
    if (isHybrid) {

    } else {

    }
  });
  appPorts.shareViaGoogle?.subscribe(({title, body, url}) => {
    if (isHybrid) {

    } else {

    }
  });
}
