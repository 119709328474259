// import {MqttClient} from 'mqtt/types/lib/client';
import {AppPorts} from './elm-ports';
import {AllPorts} from './ElmFramework/ElmFrameworkPorts';
// import {CognitoSession, SocketListeners} from './Services/aws';
// import {aws} from './Services/synchronised-async-services';
import {ServiceWorkerPostMsg} from './sw';

export {
  handleAuth,
}

// let mqttClient: MqttClient | undefined;

function handleAuth(isHybrid: boolean, appPorts: AllPorts): void {
  // appPorts.newUserAuthenticated?.subscribe(initAwsSdk(appPorts));
  // appPorts.sessionRefreshSuccess?.subscribe(refreshSessionHandler(appPorts));
  appPorts.unauthenticate?.subscribe(unauthenticate(isHybrid));
  receiveRegistrationConfirmationDetails(appPorts);
}

// const refreshSessionHandler =
//   (appPorts: AllPorts) =>
//     (session: CognitoSession): Promise<any> =>
//       aws.refreshAwsSdkSession(session)
//         .then(credentials =>
//           mqttClient && (mqttClient.disconnected || mqttClient.disconnecting) ?
//             aws
//               .toMqttClient(socketListeners(appPorts), {}, credentials)
//               .then(assignNewMqttClient) :
//             Promise.resolve(null));

function receiveRegistrationConfirmationDetails(appPorts: AppPorts): void {
  if (!navigator.serviceWorker) {
    return
  }
  navigator.serviceWorker.addEventListener('message', (msg: { data: ServiceWorkerPostMsg }) => {
    if (msg.data.kind === 'registrationConfirmationDetails') {
      appPorts.receiveRegistrationConfirmationDetails.send(msg.data);
    }
  });
}

// const initAwsSdk =
//   (appPorts: AllPorts) =>
//     (session: CognitoSession): Promise<void> =>
//       aws
//         .initSdk(socketListeners(appPorts), {}, session.idToken)
//         .then(assignNewMqttClient);

// const socketListeners = (appPorts: AllPorts): SocketListeners => ({
//   general: (payload: object) =>
//     appPorts.receivePushMsg.send({
//       receivingCircumstances: document.hasFocus() ? 'focused' : 'background',
//       data: {
//         notification: {title: '', body: ''},
//         data: {payload: JSON.stringify(payload)},
//       }
//     }),
// });


// function assignNewMqttClient(obj: { mqttClient: MqttClient }): void {
//   if (mqttClient) {
//     mqttClient.end(true);
//   }
//   mqttClient = obj.mqttClient;
// }

function unauthenticate(isHybrid: boolean) {
  return (): void => {
    clearPersistence();
    // endMqttClient();
    if (isHybrid) {
      (window.plugins && (<any>window.plugins).googleplus && (<any>window.plugins).googleplus.logout());
    }
  };
}

// function endMqttClient(): void {
//   if (mqttClient) {
//     mqttClient.end(true);
//   }
//   mqttClient = undefined;
// }

declare global {
  interface Window {
    register: any
    authenticate: any
    confirmRegistration: any
  }
}

function clearPersistence(): void {
  localStorage.clear();
}