import {
  Appearance,
  ConfirmCardPaymentData,
  PaymentIntentResult,
  SetupIntentResult,
  Stripe,
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeElements,
  StripePaymentElementChangeEvent,
  TokenResult,
  loadStripe,
} from "@stripe/stripe-js";

export {
  createPaymentElement,
  createCardElement,
  createToken,
  confirmCardSetup,
  confirmCardPayment,
  confirmSetupIntent,
  confirmPaymentIntent,
}

const stripe: Promise<Stripe | null> = stripeKeyPromise().then(loadStripe);

function confirmSetupIntent(cfg: { elementsInstance: StripeElements }): Promise<void> {
  return stripe
    .then(stripeService => stripeService?.confirmSetup({ elements: cfg.elementsInstance, redirect: "if_required" })
      .then((r: SetupIntentResult) => r.error ? Promise.reject(r.error.message) : undefined));
}

function confirmPaymentIntent(cfg: { elementsInstance: StripeElements }): Promise<void|string> {
  return stripe
    .then(stripeService => stripeService?.confirmPayment({ elements: cfg.elementsInstance, redirect: "if_required" })
      .then((r: PaymentIntentResult) => r.error ? Promise.reject(r.error.message) : r.paymentIntent.status));
}

const confirmCardSetup =
  (cfg: {cardEl: StripeCardElement, clientSecret: string}): Promise<void> =>
    stripe
      .then(stripeService =>
        stripeService?.confirmCardSetup(cfg.clientSecret, {
          payment_method: {
            card: cfg.cardEl,
          },
        })
          .then((r:SetupIntentResult) => r.error ? Promise.reject(r.error.message) : undefined));


function confirmCardPayment(cfg: {clientSecret: string, card: StripeCardElement | string, setupFutureUsage?: boolean}): Promise<void|string> {
  const confirmCardPaymentData = toConfirmCardPaymentData(cfg.card, cfg.setupFutureUsage);
  return stripe
    .then(stripeService =>
      stripeService?.confirmCardPayment(cfg.clientSecret, confirmCardPaymentData)
        .then(r => r.error ? Promise.reject(r.error.message) : r.paymentIntent.status));

  function toConfirmCardPaymentData(card: StripeCardElement | string, setupFutureUsage?: boolean): ConfirmCardPaymentData {
    if (typeof card === "string") { return { payment_method: card }; }
    return {
      payment_method: { card },
      ...(setupFutureUsage ? { setup_future_usage: "off_session" } : {}),
    };
  }
}


const createPaymentElement =
  (cfg: {
    clientSecret: string
    element: HTMLElement
    onChange: (e: StripePaymentElementChangeEvent, elementsInstance: StripeElements | undefined) => void
  }) =>
    stripe
      .then(stripeService => {
          const { clientSecret } = cfg;
          const appearance: Appearance = {
            theme: "flat",
            labels: "floating",
          };
          const elementsInstance = stripeService?.elements({ appearance, clientSecret, loader: "always" });
          const paymentElement = elementsInstance?.create("payment");
          paymentElement?.on("change", (e:StripePaymentElementChangeEvent ) => cfg.onChange(e, elementsInstance));
          paymentElement?.mount(cfg.element);
          return paymentElement;
        });

const createCardElement =
  (cfg: {
    element: HTMLElement,
    classes: string,
    onChange: (e: StripeCardElementChangeEvent, card: StripeCardElement) => void
  }): Promise<StripeCardElement | null> =>
    stripe
      .then(stripeService => {
        const card =
          stripeService
            ?.elements()
            .create('card', {
              style: {
                base: {
                  lineHeight: '3',
                }
              },
              classes: { base: cfg.classes },
            }) ?? null;
        card?.on('change', (e:StripeCardElementChangeEvent) => cfg.onChange(e, card));
        card?.mount(cfg.element);
        return card;
      });

const createToken =
  (card: StripeCardElement): Promise<string | null> =>
    stripe
      .then(s =>
        s?.createToken(card)
          .then((r: TokenResult) =>
            r.error
              ? Promise.reject(r.error)
              : (r.token?.id ?? null)
          ) ?? null);

function stripeKeyPromise(): Promise<string> {
  return new Promise(resolve => {
    if ((<any> process).env.config.stripeKey) {
      resolve((<any> process).env.config.stripeKey);
    }
  }) ;
}
