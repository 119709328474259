import {ElmLitElement} from "../ElmLitElement";

export {
  defineVideoInputElement,
}

const defineVideoInputElement = (): void =>
  customElements.define('bky-video-input', VideoInput);


class VideoInput extends ElmLitElement {

  connectedCallback(): void {
    const theInput = this.querySelector<HTMLInputElement>('input');
    theInput
      ?.addEventListener('change', e => {
        const file = (e.target as HTMLInputElement).files?.[0];
        theInput.value = '';
        file && this.fire('onVideo', {
          blob: new Blob([file]),
          localUrl: URL.createObjectURL(file),
        });
      });
  }
}



// function justVideo(video: Blob): Promise<ResizedVideo> {
//   return Promise.resolve({
//     width: 0, //todo: implement measurements
//     height: 0,
//     size: 0,
//     videoBlob: video,
//     blobUrl: window.URL.createObjectURL(video),
//   });
// }




//------------------------------------------------------------------------------------


