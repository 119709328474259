import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';

firebase.initializeApp((<any> process).env.config.firebase);

export {
  auth,
  messaging,
  scopedAppleAuthProvider,
  scopedGoogleAuthProvider,
}
const messaging: firebase.messaging.Messaging | undefined = firebase.messaging.isSupported() ? firebase.messaging() : undefined;

const auth = firebase.auth();

function scopedGoogleAuthProvider(scopes: string[] = ["email", "profile"]): firebase.auth.GoogleAuthProvider {
  let googleAuthProvider = new firebase.auth.GoogleAuthProvider();
  scopes.forEach((scope: string) => { googleAuthProvider.addScope(scope); })

  return googleAuthProvider;
}

function scopedAppleAuthProvider(scopes: string[] = ["email", "name"]): firebase.auth.OAuthProvider {
  let appleAuthProvider = new firebase.auth.OAuthProvider("apple.com");
  scopes.forEach((scope: string) => { appleAuthProvider.addScope(scope); });

  return appleAuthProvider;
}
