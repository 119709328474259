import { defineCustomizedSlideSelectVerticalElement } from '../ElmFramework/Lib/Ui/SlideSelectVertical/SlideSelectVertical';
import { translateRatio } from '../ElmFramework/Lib/Ui/SlideSelectHorizontal/SlideSelectUtil';

const componentName = 'customized-vertical-slide-select' as const;

export {
  defineSelectVerticalElement,
}

function defineSelectVerticalElement():void {
  defineCustomizedSlideSelectVerticalElement({
    componentName,
    elMutator: mutateItem,
  });
}


function mutateItem(el: HTMLElement, ratio: number, where: 'top' | 'bottom'): void {
  if (where == 'top') {
    (el.firstChild as HTMLElement).style.transform =
      `translate3d(0, -${translateRatio(ratio, 0.75, 0.99, 37, 0, [.71,.01,.63,.99])}%, 0)`;
  } else {
    (el.firstChild as HTMLElement).style.transform =
      `translate3d(0, ${translateRatio(ratio, 0.75, 0.99, 37, 0, [.71,.01,.63,.99])}%, 0)`;
  }
  (el.firstChild?.firstChild?.firstChild as HTMLElement).style.transform =
    `rotateY(${translateRatio(ratio, .3, 1, 90, 0, [.5,0,1,0])}deg) scaleY(${translateRatio(ratio, 0.8, 0.97, 0.6, 1)})`;
}
