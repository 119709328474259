import * as ErrorReporter from "../ErrorReporter/ErrorReporter"
import {ElmFrameworkPorts} from "../../ElmFrameworkPorts";

export {
  handlePayments,
}

function handlePayments(appPorts: ElmFrameworkPorts): void {
  appPorts.sendPaymentRequest?.subscribe(handlePaymentReq(appPorts));
}

const handlePaymentReq =
  (appPorts: ElmFrameworkPorts) =>
    ({label, currency, amount}: {label: string, currency: string, amount: string}): void => {
      getPaymentCardInfo(label, currency, amount)
        .then(paymentResponse => {
          const paymentDetails: PaymentResponseDetails = paymentResponse.details;
          paymentResponse.complete();
          appPorts.userProvidesPaymentCard.send({
            cardNo: paymentDetails.cardNumber,
            expMonth: paymentDetails.expiryMonth,
            expYear: paymentDetails.expiryYear,
            cv2: paymentDetails.cardSecurityCode,
          });
        }).catch(err => {
        ErrorReporter.captureException(err);
        appPorts.userCouldNotProvidePaymentCard.send(null);
      });
    };




function getPaymentCardInfo(label: string, currency: string, amount: string): Promise<PaymentResponse> {
  return (new PaymentRequest([{
    supportedMethods: 'basic-card',
    data: {
      supportedNetworks: ['visa', 'mastercard', 'amex', 'jcb', 'diners', 'discover', 'mir', 'unionpay'],
      supportedTypes: ['credit', 'debit']
    }
  }], {
    total: {
      label,
      amount: {currency, value: amount}
    },
  })).show();
}

interface PaymentResponseDetails {
  billingAddress: {
    addressLine: string[]
    city: string
    country: string
    dependentLocality: string
    languageCode: string
    organization: string
    phone: string
    postalCode: string
    recipient: string
    region: string
  }
  cardNumber: string
  cardSecurityCode: string
  cardholderName: string
  expiryMonth: string
  expiryYear: string
}
