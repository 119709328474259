import {ElmLitElement} from '../ElmLitElement';
import {observeDom} from "../util";

export {
  defineTextAreaSelfSize
};

const defineTextAreaSelfSize = () =>
  customElements.define('textarea-self-size', TextAreaSelfSize);


class TextAreaSelfSize extends ElmLitElement {
  private disconnect : Function | undefined;

  connectedCallback() {
    super.connectedCallback();
    this.disconnect = init(this);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.disconnect && this.disconnect();
  }
}

function init(thisEl: TextAreaSelfSize): Function {
  let childStyles: CSSStyleDeclaration | undefined;
  let observer : MutationObserver | undefined;

  thisEl.addEventListener('input', handleInput, true);

  function handleInput(event: Event): void {
    if (event.target instanceof HTMLElement) {
      initializeClassAttributeObserver(event.target);
      initializeStyleObject(event.target);
      handleElementGrowth(event.target)
    }
  }

  function handleElementGrowth(element: HTMLElement) {
    if (childStyles) {
      const maxH = Number.parseFloat(childStyles.getPropertyValue('max-height'));
      const borderTopH = Number.parseFloat(childStyles.getPropertyValue('border-width-top'));
      const borderBottomH = Number.parseFloat(childStyles.getPropertyValue('border-width-bottom'));

      if (maxH > element.scrollHeight) {
        element.style.height = `auto`;
        element.style.height = `${element.scrollHeight + borderTopH + borderBottomH}px`;
        element.style.overflowY = `hidden`;
      } else {
        element.style.height = `${maxH}px`;
        element.style.overflowY = `auto`;
      }
    }
  }

  function initializeClassAttributeObserver(element: HTMLElement) {
    if (!observer) {
      observer = observeDom(
        element,
        handleClassAttributeChanged,
        { attributeFilter: ['class'], attributeOldValue: true }
      );
    }

    function handleClassAttributeChanged(mutationRecords: MutationRecord[]) {
      mutationRecords.forEach((record: MutationRecord) => {
        if (hasAttributeChanged(record)) {
          cacheStyleObject(window.getComputedStyle(record.target as HTMLElement));
        }
      });

      function hasAttributeChanged(mutationRecord: MutationRecord): Boolean {
        if (!mutationRecord.attributeName) return false;
        const target = mutationRecord.target as HTMLElement;
        return mutationRecord.oldValue !== target.getAttribute(mutationRecord.attributeName);
      }
    }
  }

  function initializeStyleObject(element: HTMLElement) {
    if (!childStyles) cacheStyleObject(window.getComputedStyle(element));
  }

  function cacheStyleObject(newStyleObject: CSSStyleDeclaration) {
    childStyles = newStyleObject;
  }

  return () => {
    thisEl.removeEventListener('input', handleInput, true);
    observer && observer.disconnect();
    observer = undefined;
    childStyles = undefined;
  }
}
