import {synchroniseAsyncService} from "./Lib/util";

export const webPush = synchroniseAsyncService({
  service: import('./Feature/PushMessages/web-push-notifications'),
  api: ['enableWebNotifications', 'getPushPermissionsStatus', 'onTokenRefresh']
});
export const asyncPolyfills = synchroniseAsyncService({
  service: import('./Feature/Platform/async-polyfills'),
  api: ['initAsyncPolyfills']
});
export const hybridMediaBtn = synchroniseAsyncService({
  service: import('./Lib/Ui/HybridMediaButton/HybridMediaButton'),
  api: ['defineHybridMediaElement', 'isHybridMediaButtonTakingPicture']
});
export const googleMapWrapElement = synchroniseAsyncService({
  service: import('./Lib/Ui/GoogleMap/GoogleMapWrap'),
  api: ['defineGoogleMapWrapElement']
});
export const googlePlaces = synchroniseAsyncService({
  service: import('./Feature/Places/Places'),
  api: ['getPlacePredictions']
});
