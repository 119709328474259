import {property} from 'lit-element';
import {ElmLitElement} from '../ElmLitElement';
import {onImgLoad} from '../util';

export {
  definePatientBackgroundImageElement,
};

const definePatientBackgroundImageElement = (): void =>
  customElements.define('patient-background-image', PatientBackgroundImage);


const intersectionObserver =
  IntersectionObserver && new IntersectionObserver(entries =>
    entries.forEach(entry =>
      entry.isIntersecting
      && entry.target instanceof PatientBackgroundImage
      && loadImg(entry.target)));


class PatientBackgroundImage extends ElmLitElement {
  @property({type: String, attribute: 'wait-for-image'}) waitForImage: string;

  attributeChangedCallback(name: string, old: string | null, value: string | null): void {
    super.attributeChangedCallback(name, old, value);
    switch (name) {
      case 'wait-for-image': {
        if (this.parentNode && this.firstChild instanceof Element) {
          this.firstChild.classList.add('hidden');
          if (this.waitForImage !== '') {
            loadImg(this);
          }
        }
        break;
      }
    }
  }

  connectedCallback(): void {
    observeImage(this);
  }

  disconnectedCallback(): void {
    unobserveImage(this);
  }
}


function observeImage(el: PatientBackgroundImage) {
  if (intersectionObserver) {
    intersectionObserver.observe(el);
  } else {
    loadImg(el);
  }
}

function unobserveImage(el: PatientBackgroundImage) {
  if (intersectionObserver) {
    intersectionObserver.unobserve(el);
  }
}


function loadImg(el: PatientBackgroundImage) {
  const child = <HTMLElement>el.firstChild;
  const imgUrl = el.waitForImage;
  requestAnimationFrame(() => child.style.backgroundImage = 'url(' + imgUrl + ')');
  unobserveImage(el);
  onImgLoad(imgUrl, () =>
    requestAnimationFrame(() =>
      child.classList.remove('hidden')));
}
