import { ElmFrameworkPorts } from '../../ElmFrameworkPorts';
import {confirmCardPayment, confirmCardSetup, confirmPaymentIntent, confirmSetupIntent} from './stripe-service';

export {
  handleStripePorts,
}

function handleStripePorts(ports: ElmFrameworkPorts): void {
  ports.confirmStripeCardSetup?.subscribe(cmd =>
    confirmCardSetup(cmd)
      .then(() => ports.receiveStripeConfirmCardSetupSuccess?.send(null))
      .catch(err => ports.receiveStripeConfirmCardSetupFailure?.send(
        typeof err === 'string' ? err : err.message
      )));

  ports.confirmStripeSetup?.subscribe(cmd =>
    confirmSetupIntent(cmd)
      .then(() => ports.receiveStripeConfirmSetupSuccess?.send(null))
      .catch(err => ports.receiveStripeConfirmSetupFailure?.send(
        typeof err === "string" ? err : err.message
      ))
  );

  ports.confirmStripeCardPayment?.subscribe(cmd =>
    confirmCardPayment(cmd)
      .then((status) => ports.receiveStripeConfirmCardPaymentSuccess?.send(typeof status === "string" ? status : ""))
      .catch(err => ports.receiveStripeConfirmCardPaymentFailure?.send(
        typeof  err === 'string' ? err : err.message
      ))
  );

  ports.confirmStripePayment?.subscribe(cmd =>
    confirmPaymentIntent(cmd)
      .then((status) => ports.receiveStripeConfirmPaymentSuccess?.send(typeof status === "string" ? status : ""))
      .catch(err => ports.receiveStripeConfirmPaymentFailure?.send(
        typeof err === "string" ? err : err.message
      ))
  );
}
