import { property } from 'lit-element';
import { ElmLitElement } from '../ElmLitElement';
import { toTransitionDuration } from '../util';

export {
  defineDramaElement,
};

const defineDramaElement = (): void =>
  customElements.define('ef-drama', DramaView);


class DramaView extends ElmLitElement {
  @property({type: String, attribute: 'curr-view-transition-identifier'}) currViewTransitionIdentifier: string;
  @property({type: String, attribute: 'previous-view-transition-identifier'}) previousViewTransitionIdentifier?: string;
  @property({type: String, attribute: 'curr-view-scroll-context-selector'}) currViewScrollContextSelector: string;
  @property({type: String, attribute: 'curr-view-identifier'}) currViewIdentifier: string;
  private previousViewIdentifier: string;
  private previousViewScrollContextSelector: string;
  private scrollPositions: { [k: string]: number } = {};

  attributeChangedCallback(name: string, old: string | null, value: string | null): void {
    super.attributeChangedCallback(name, old, value);
    if (name === 'curr-view-scroll-context-selector') {
      if (!this.currViewIdentifier) {
        this.previousViewScrollContextSelector = value || '';
      } else {
        this.previousViewScrollContextSelector = old || '';
      }
    } else if (name === 'curr-view-identifier') {
      this.previousViewIdentifier = old || '';
    }
  }

  removeChild<T extends Node>(oldChild: T): T {
    const theOldChild = oldChild as unknown as HTMLElement;
    const scrollingEl = toScrollingElement(theOldChild, this.previousViewScrollContextSelector);
    if (scrollingEl) {
      this.scrollPositions[this.previousViewIdentifier] = scrollingEl.scrollTop;
    }
    if (this.previousViewIdentifier) {
      this.previousViewTransitionIdentifier && setExitingState(theOldChild, this.previousViewTransitionIdentifier);
    } else {
      theOldChild.remove();
    }
    return oldChild;
  }

  appendChild<T extends Node>(fragment: T): T {
    const newChild = (fragment.nodeName === '#document-fragment' ? fragment.firstChild : fragment) as HTMLElement;
    setTransitionAttr(newChild, this.currViewTransitionIdentifier + '-start');
    insertAsFirstChild(newChild, this); //because that's what Elm expects
    this.setScrollPositionForNewView(newChild);
    setTimeout(() => setTransitionAttr(newChild, this.currViewTransitionIdentifier));
    return fragment;
  }

  setScrollPositionForNewView(newChild: HTMLElement): void {
    const scrollingElement = toScrollingElement(newChild, this.currViewScrollContextSelector);
    if (scrollingElement) {
      scrollingElement.scrollTop = this.scrollPositions[this.currViewIdentifier];
    }
  }
}

//________________________________________________________________________________________

function toScrollingElement(parent: HTMLElement, selector: string): HTMLElement | null {
  return selector
    ? parent.matches(selector) && parent || parent.querySelector(selector)
    : null;
}


function insertAsFirstChild(newChild: HTMLElement, parent: HTMLElement): void {
  if (parent.firstChild) {
    parent.insertBefore(newChild, parent.firstChild);
  } else {
    Element.prototype.appendChild.call(parent, newChild);
  }
}

function setExitingState(view: HTMLElement, transitionIdentifier: string): void {
  //the reason we're waiting for an event loop is because we can be in a state where elm
  // wants to remove the view in the very same event loop that it placed it in the dom
  setTimeout(() => {
    setTransitionAttr(view, transitionIdentifier);
    setTimeout(() => view.remove(), toTransitionDuration(view));
  });
}

function setTransitionAttr(view: HTMLElement, transitionIdentifier: string): void {
  view.setAttribute('ef-drama', transitionIdentifier);
}


