import {AppPorts} from "../elm-ports";
import * as ErrorReporter from '../ElmFramework/Feature/ErrorReporter/ErrorReporter'

const secureStorage: Promise<SecureStorage> = getSecureStorage();

export {
  handleSecureStorageIfSupported
}


function handleSecureStorageIfSupported(appPorts: AppPorts): void {
  secureStorage
    .then(ss => handleSecureStorage(appPorts, ss))
    .catch(() => appPorts.receiveVault.send({isSupported: false, vault: null}));
}

function handleSecureStorage(appPorts: AppPorts, ss: SecureStorage): void {
  ss.get('vault')
    .catch(() => null)
    .then(vault => appPorts.receiveVault.send({isSupported: true, vault}));
  appPorts.setVault.subscribe(vault =>
    ss.set('vault', vault)
      .catch(err =>
        ErrorReporter.captureMessage('problem adding vault to the secure storage'))); // removed second param {extra: err}, add if sentry is re-enabled
  appPorts.clearVault.subscribe(() =>
    ss.remove('vault')
      .catch(err =>
        ErrorReporter.captureMessage('problem removing vault from secure storage'))); // removed second param {extra: err}, add if sentry is re-enabled
}


function getSecureStorage(): Promise<SecureStorage> {
  return new Promise((resolve, reject) => {
    try {
      document.addEventListener('deviceready', () => {
        const ss = new (<any>cordova.plugins).SecureStorage(
          () => resolve(createSecureStorage(ss)),
            (err: any) => reject(err),
          'ggSecureStorage'
        );
      }, false);
    } catch (ex) {
      reject(ex);
    }
  });
}

function createSecureStorage(storage: any): SecureStorage {
  const ss = storage;
  // @ts-ignore
  window['ss'] = ss;
  return {
    get: (k: string): Promise<string> => new Promise((resolve, reject) =>
      ss.get(resolve, reject, k)),
    set: (k: string, v: string): Promise<string> => new Promise((resolve, reject) =>
      ss.set(resolve, reject, k, v)),
    remove: (k: string): Promise<string> => new Promise((resolve, reject) =>
      ss.remove(resolve, reject, k)),
  };
}


interface SecureStorage {
  get: (k: string) => Promise<string>
  set: (k: string, v: string) => Promise<string>
  remove: (k: string) => Promise<string>
}