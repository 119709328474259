import {ElmFrameworkPorts} from "../../../ElmFrameworkPorts";

export {
  handleGoogleMapPorts,
}

function handleGoogleMapPorts(appPorts: ElmFrameworkPorts): void {
  appPorts.setCenterAndZoomPort?.subscribe(({identifier, center, zoom}) => {
    const map = getGMap(identifier);
    map.setZoom(zoom);
    map.panTo(center);
  });

  // setCenterPort?.subscribe(({identifier, center}) => {
  //   const map = getGMap(identifier);
  //   map.setOptions({center});
  // });
}

function getGMap(identifier: string) {
  return (<any>document.getElementById(identifier)).map;
}