import { ElmLitElement, fireEvent } from '../../ElmLitElement';
import { debounce } from 'debounce';
import { alwaysVoid, observeDom } from '../../util';

const selectedItemAttr = 'selected-item' as const;
const scrollSettledEvent = 'scrollSettled' as const;
const debouncedVibrate = toVibrateEffect();

export {
  defineSlideSelectVerticalElement,
  defineCustomizedSlideSelectVerticalElement,
};

const defineCustomizedSlideSelectVerticalElement = (config: {
  componentName: string,
  elMutator: ElementStyleMutatorOnScroll
}): void =>
  customElements.define(config.componentName, class CustomizedSlideSelectVertical extends SlideSelectVertical {
    elMutator = config.elMutator
  });

const defineSlideSelectVerticalElement = (): void =>
  customElements.define('slide-select-vertical', SlideSelectVertical);

class SlideSelectVertical extends ElmLitElement {
  disconnectListeners = alwaysVoid;
  elMutator: ElementStyleMutatorOnScroll | undefined;
  
  connectedCallback(): void {
    setHalfHeightPadding(this);
    const scrollerState = toScrollerState(this);
    const scrollToSelectedItemDebounced = debounce(() => scrollToSelectedItemIfNotInteracting(this, scrollerState), 500);
    const fireSelectedDebounced = debounce(handleFiringEvent, 400);
    const markDoneScrollingDebounced = debounce(onDoneScrolling, 400);
    const scrollHandler = toScrollHandler(this, scrollerState, fireSelectedDebounced, scrollToSelectedItemDebounced, markDoneScrollingDebounced, this.elMutator);
    const destroyReInitObservers = updateScrollerStateOnEvents(this, scrollerState);
    this.addEventListener('scroll', scrollHandler);
    this.addEventListener('touchstart', onTouchStart)
    this.addEventListener('touchend', onTouchDone);
    this.addEventListener('touchcancel', onTouchDone);
    const selectedItemObserver = observeDom(this, scrollToSelectedItemDebounced, {subtree: true, attributeFilter: [selectedItemAttr]});
    scrollToSelectedItem(this, true);
    
    this.disconnectListeners = () => {
      scrollToSelectedItemDebounced.clear();
      fireSelectedDebounced.clear();
      destroyReInitObservers();
      this.removeEventListener('scroll', scrollHandler);
      selectedItemObserver.disconnect();
    };
    
    function onTouchStart(): void {
      scrollToSelectedItemDebounced.clear();
      fireSelectedDebounced.clear();
      scrollerState.isTouched = true;
    }
    function onTouchDone(): void {
      scrollerState.isTouched = false;
      onDoneInteracting();
    }
    function onDoneScrolling(): void {
      scrollerState.isScrolling = false;
      onDoneInteracting();
    }
    function onDoneInteracting(): void {
      if (scrollerState.pendingSelectedItem && !scrollerState.isTouched && !scrollerState.isScrolling) {
        fireSelectedItem(scrollerState, handleFiringEvent, scrollerState.pendingSelectedItem);
      }
    }
  }
  
  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.disconnectListeners();
  }
}

function scrollToSelectedItemIfNotInteracting(slider: SlideSelectVertical, state: ScrollerState): void {
  if (!state.isScrolling && !state.isTouched) {
    scrollToSelectedItem(slider);
  }
}


function setHalfHeightPadding(slider: SlideSelectVertical): void {
  slider.style.paddingTop = `${slider.offsetHeight/2}px`;
  slider.style.paddingBottom = `${slider.offsetHeight/2}px`;
}
function removePadding(slider: SlideSelectVertical): void {
  slider.style.paddingTop = '';
  slider.style.paddingBottom = '';
}



function toScrollerState(slider: SlideSelectVertical): ScrollerState {
  return {
    items: toItemsPlacements(slider),
    height: slider.offsetHeight,
    half: slider.offsetHeight / 2,
    isTouched: false,
    isScrolling: false,
  };
}

function updateScrollerStateOnEvents(slider: SlideSelectVertical, args: ScrollerState): () => void {
  window.addEventListener('resize', debounce(updateStuff, 400));
  window.addEventListener('load', debounce(updateStuff, 400));
  const domObserver = observeDom(slider, measureAndDrawThings, {childList: true});
  
  return () => {
    window.removeEventListener('resize', updateStuff);
    window.removeEventListener('load', updateStuff);
    domObserver.disconnect();
  };
  
  function updateStuff() {
    removePadding(slider);
    setTimeout(measureAndDrawThings);
  }
  function measureAndDrawThings(): void {
    setHalfHeightPadding(slider);
    args.items = toItemsPlacements(slider);
    args.height = slider.offsetHeight;
    args.half = slider.offsetHeight / 2;
    scrollToSelectedItem(slider, true);
  }
}

interface ScrollerState {
  items: ElWithLocation[],
  height: number,
  half: number,
  currElInFocus?: HTMLElement,
  isTouched: boolean,
  isScrolling: boolean,
  pendingSelectedItem?: ElWithLocation,
}

function toScrollHandler(
  slider: SlideSelectVertical,
  state: ScrollerState,
  debouncedFire: (el: ElWithLocation) => void,
  debouncedScrollToSelectedItem: () => void,
  debouncedMarkScrollingDone: () => void,
  mutateItem: ElementStyleMutatorOnScroll | undefined
) {
  return () => {
    const scroll = slider.scrollTop;
    const scrollCenter = scroll + state.half;
    state.isScrolling = true;
    debouncedMarkScrollingDone();
    requestAnimationFrame(() => {
      let newElSelected = false;
      state.items.forEach(item => {
        const loc = item.location;
        if (loc > scroll - state.half && loc <= scrollCenter) {
          mutateItem?.(item.el, (loc - scroll) / state.half, 'top');
          if (state.currElInFocus && isNewItemInFocus(state.currElInFocus, item, scrollCenter)) {
            state.currElInFocus = item.el;
            fireSelectedIfNotTouchingOrScrolling(item);
            debouncedVibrate();
            newElSelected = true;
          }
        } else if (loc > scrollCenter && loc < scroll + state.height + state.half) {
          mutateItem?.(item.el, 1 - ((loc - scroll) - state.half) / state.half, 'bottom');
          if (state.currElInFocus && isNewItemInFocus(state.currElInFocus, item, scrollCenter)) {
            state.currElInFocus = item.el;
            fireSelectedIfNotTouchingOrScrolling(item);
            debouncedVibrate();
            newElSelected = true;
          }
        }
      });
      if (!newElSelected && !state.isTouched && !state.isScrolling) {
        debouncedScrollToSelectedItem();
      }
    });
    
    function fireSelectedIfNotTouchingOrScrolling(item: ElWithLocation): void {
      if (state.isTouched || state.isScrolling) {
        state.pendingSelectedItem = item;
      } else {
        fireSelectedItem(state, debouncedFire, item);
      }
    }
  };
}

function fireSelectedItem(state: ScrollerState, debouncedFire: (item: ElWithLocation) => void, item: ElWithLocation):void {
  debouncedFire(item);
  state.pendingSelectedItem = undefined;
}


function isNewItemInFocus(oldEl: HTMLElement, el: ElWithLocation, scrollCenter: number): boolean {
  return oldEl !== el.el
    && el.startsAt < scrollCenter && scrollCenter < el.endsAt;
}

function handleFiringEvent(item: ElWithLocation): void {
  fireEvent(item.el, scrollSettledEvent);
}

function scrollToSelectedItem(container: SlideSelectVertical, preventSmooth?: boolean): void {
  const candidate: HTMLElement | null = container.querySelector(`[${selectedItemAttr}]`);
  if (candidate) {
    container.scrollTo({
      top: candidate.offsetTop + (candidate.offsetHeight / 2) - (container.offsetHeight / 2),
      behavior: preventSmooth ? 'auto' : 'smooth',
    });
  }
}

type ElementStyleMutatorOnScroll = (el: HTMLElement, ratio: number, where: 'top' | 'bottom') => void;

function toItemsPlacements(parent: HTMLElement): ElWithLocation[] {
  return Array.from(parent.childNodes).map(e => {
    const el = e as HTMLElement;
    return {
      el,
      location: el.offsetTop + el.offsetHeight / 2,
      startsAt: el.offsetTop,
      endsAt: el.offsetTop + el.offsetHeight,
    };
  });
}

interface ElWithLocation {
  el: HTMLElement,
  location: number,
  startsAt: number, //top edge of element
  endsAt: number, //bottom edge of element
}

function toVibrateEffect(): () => void {
  return navigator.vibrate ?
    () => navigator.vibrate(2) :
    alwaysVoid;
}


