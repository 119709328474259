import {googlePlaces} from "../../synchronised-async-services";
import {ElmFrameworkPorts} from "../../ElmFrameworkPorts";

export {
  PlacePrediction,
  PlacePredictionsOutput,
  handlePlacesPorts,
};

interface PlacePrediction {
  placeId: string;
  placeDescription: string;
  types: string[];
}
interface PlacePredictionsOutput {
  input: string;
  maybePlacePredictions: Array<PlacePrediction> | null;
}

function handlePlacesPorts({ getPlacePredictions, receivePlacePredictions }: ElmFrameworkPorts): void {
  getPlacePredictions?.subscribe(async (input: string) => {
    try {
      const predictions = await googlePlaces.getPlacePredictions(input);
      const placePredictions = { input, maybePlacePredictions: transformToPlacePredictions(predictions) };
      receivePlacePredictions.send(placePredictions);
    } catch (err) {
      console.error("Some error occurred", err);
      receivePlacePredictions.send({ input, maybePlacePredictions: null });
    }
  });

  function transformToPlacePredictions( predictions: google.maps.places.AutocompletePrediction[] ): PlacePrediction[] {
    return predictions
      .filter((prediction) => Boolean(prediction.place_id))
      .map(({ place_id, description, types }) => ({
        placeId: place_id,
        placeDescription: description,
        types: types
      })) as PlacePrediction[];
  }
}
