import {ElmLitElement} from "../ElmLitElement";
import {html, property, TemplateResult} from "lit-element";

export {
  defineVideoThumbnailElement,
}

const defineVideoThumbnailElement = (): void =>
  customElements.define('video-thumbnail', VideoThumbnail);

const intersectionObserver =
  IntersectionObserver && new IntersectionObserver(entries =>
    entries.forEach(entry => {
      if (entry.target instanceof VideoThumbnail) {
        if (entry.isIntersecting) {
          loadImg(entry.target);
        }
      }
    }));



class VideoThumbnail extends ElmLitElement {
  @property({type: String, attribute: 'thumbnail-classes'}) thumbnailClasses: string;
  @property({type: String, attribute: 'video-url'}) videoUrl: string;
  @property({type: Number, attribute: 'playback-rate'}) playbackRate: number;

  connectedCallback(): void {
    observeImage(this);
    super.connectedCallback();
  }

  disconnectedCallback(): void {
    unobserveImage(this);
    super.disconnectedCallback();
  }
  protected render(): TemplateResult | void {
    return html`
        <img
          class="relative hidden fullWidth fullHeight objectFitCoverCenter ${this.thumbnailClasses}"
          src="${this.videoUrl}"
          alt="video thumbnail"
        >
      `;
  }

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }
}


function observeImage(el: VideoThumbnail) {
  if (intersectionObserver) {
    intersectionObserver.observe(el);
  } else {
    loadImg(el);
  }
}

function unobserveImage(el: VideoThumbnail) {
  if (intersectionObserver) {
    intersectionObserver.unobserve(el);
  }
}



function loadImg(el: VideoThumbnail) {
  const child = <HTMLImageElement>el.firstElementChild;
  requestAnimationFrame(() => {
    child.classList.remove('hidden');
  });
}


// function unloadImg(el: VideoThumbnail) {
//   const child = <HTMLImageElement>el.firstElementChild;
//   requestAnimationFrame(() => child.classList.add('hidden'));
// }
