import {ElmFrameworkPorts, handlePortLoopback} from "../../ElmFrameworkPorts";

export {
  handleNotifications,
}

function handleNotifications(appPorts: ElmFrameworkPorts): void {
  handlePortLoopback({
    portOut: appPorts.emitNotif,
    portIn: appPorts.listenNotif,
  });
}
