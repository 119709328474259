import { property } from 'lit-element';
import { ElmLitElement } from '../ElmLitElement';
import {createCardElement, createPaymentElement} from './stripe-service';
import {alwaysVoid} from "../util";

export {
  defineStripeInputElement,
};

const defineStripeInputElement = (): void =>
  customElements.define('stripe-input', StripeInputElement);


type StripeInputElementType = "card" | "payment";

class StripeInputElement extends ElmLitElement {
  @property({type: String, attribute: 'stripe-classes'}) stripeClasses: string;
  @property({type: String, attribute: 'element-type'}) elementType: StripeInputElementType = "payment";
  @property({type: String, attribute: 'client-secret'}) clientSecret: string = "";

  connectedCallback(): void {
    switch (this.elementType) {
      case "card":
        createCardElement({
          element: this,
          classes: this.stripeClasses,
          onChange: (e, card) =>
            e.error
              ? this.fire('onInputError', e.error.message)
              : this.fire('onValidInput', card),
        }).then(alwaysVoid);
        break;
      case "payment":
        createPaymentElement({
          clientSecret: this.clientSecret,
          element: this,
          onChange: (e, paymentElement) => {
            e.complete
              ? this.fire('onValidInput', paymentElement)
              : this.fire('onInputError', "")
          },
        }).then(alwaysVoid);
        break;
    }
  }
  
  disconnectedCallback(): void {
    super.disconnectedCallback();
    // https://github.com/ionic-team/cordova-plugin-ionic-keyboard#keyboardhide
    (window as any).Keyboard?.hide?.();
  }
}

